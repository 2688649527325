import { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { MdOutlineClose, MdArrowBack, MdDeleteOutline, MdCheck } from "react-icons/md";

function VideoProcessBtnGroupMobile({ removeVideo, handlePublish }) {
    const [showDeleteDetail, setShowDeleteDetail] = useState(false);
    const [showSubmitDetail, setShowSubmitDetail] = useState(false);

    return (<div className="video-process-btn-layout-mobile">
        {!showSubmitDetail && (showDeleteDetail ? <div className="d-block w-100">
            <button
                className="btn video-process-btn-mobile w-100"
            >
                <MdOutlineClose className="text-danger" /> <span>Delete Videos?</span>
            </button>
            <div className="d-flex mt-2">
                <button
                    className="btn video-process-btn-mobile w-100 me-1"
                    onClick={() => setShowDeleteDetail(false)}
                >
                    <MdArrowBack className="text-white" /> <span>Cancel</span>
                </button>
                <button
                    className="btn video-process-btn-mobile w-100 process-fullfill-btn-mobile ms-1"
                    onClick={removeVideo}
                >
                    <MdDeleteOutline className="text-white" /> <span>Delete</span>
                </button>
            </div>
        </div> : <button
            className="btn video-process-btn-mobile"
            onClick={() => setShowDeleteDetail(true)}
        >
            <MdOutlineClose className="text-danger" />
        </button>)}
        {!showDeleteDetail && (showSubmitDetail ? <div className="d-block w-100">
            <button
                className="btn video-process-btn-mobile w-100"
            >
                <span>Submit</span> <FiCheckCircle className="ms-1" />
            </button>
            <div className="d-flex mt-2">
                <button
                    className="btn video-process-btn-mobile w-100 me-1"
                    onClick={() => setShowSubmitDetail(false)}
                >
                    <MdArrowBack className="text-white" /> <span>Return</span>
                </button>
                <button
                    className="btn video-process-btn-mobile w-100 process-fullfill-btn-mobile ms-1"
                    onClick={handlePublish}
                >
                    <MdCheck className="text-white" /> <span>Confirm</span>
                </button>
            </div>
        </div> : <button
            className="btn video-process-btn-mobile process-fullfill-btn-mobile"
            onClick={() => setShowSubmitDetail(true)}
        // disabled={!records.length}
        >
            Submit
            <FiCheckCircle className="ms-1" />
        </button>)}
    </div>)
}

export default VideoProcessBtnGroupMobile;