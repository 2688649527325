function isValidHttpUrl(string) {
  const pattern = new RegExp(
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(string);
}

function isValidTime(time) {
  let check_min = time.split(" ")[0].includes("_");
  let check_sec = time.split(" ")[2].includes("_");
  if (!check_min && !check_sec) return true;
  else return false;
}

function makeTime(time) {
  let seconds = parseInt(time);
  let min = "";
  let sec = "";
  if (Math.floor(seconds / 60) > 9) min = Math.floor(seconds / 60);
  else min = "0" + Math.floor(seconds / 60);
  if (seconds % 60 > 9) sec = seconds % 60;
  else sec = "0" + (seconds % 60);
  return `${min} : ${sec}`;
}

function checkLink(link) {
  let check = link.includes("http://") || link.includes("https://");
  if (check) return link;
  else return `https://${link}`;
}

function add_sort_records(records, record) {
  let temp_records = [...records];
  let find_index = records.findIndex(
    (each) => each.time_slot === record.time_slot
  );
  if (find_index >= 0) {
    temp_records[find_index] = record;
  } else temp_records.push(record);
  temp_records.sort((a, b) => a.time_slot - b.time_slot);
  return temp_records;
}

function remove_sort_video(video, id) {
  let temp_video = { ...video };
  let temp_records = [...video.records];
  let find_index = video.records.findIndex((each) => each.id === id);
  if (find_index >= 0) {
    temp_records.splice(find_index, 1);
  }
  temp_records.sort((a, b) => a.time_slot - b.time_slot);
  temp_video.records = temp_records;
  return temp_video;
}

function check_video_time_list(time, time_slot, hiddenTime) {
  if (
    time >= parseInt(time_slot) - parseInt(hiddenTime) &&
    time < parseInt(time_slot) + parseInt(hiddenTime)
  )
    return true;
  else return false;
}

const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

function check_time_fragment(time) {
  if (isNaN(time)) return false;
  else {
    if (parseInt(time) < 60) return true;
    else return false;
  }
}

function selectRandomVideos(videoList, numVideos) {
  const selectedVideos = [];
  const totalVideos = videoList.length;

  // Ensure numVideos is not greater than totalVideos
  numVideos = Math.min(numVideos, totalVideos);

  // Generate random indices and select videos
  while (selectedVideos.length < numVideos) {
    const randomIndex = Math.floor(Math.random() * totalVideos);
    const randomVideo = videoList[randomIndex];
    if (!selectedVideos.includes(randomVideo)) {
      selectedVideos.push(randomVideo);
    }
  }

  return selectedVideos;
}

export {
  isValidHttpUrl,
  isValidTime,
  makeTime,
  checkLink,
  add_sort_records,
  remove_sort_video,
  check_video_time_list,
  unsecuredCopyToClipboard,
  check_time_fragment,
  selectRandomVideos
};
