import React from "react";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
import { SlClose } from "react-icons/sl";
import { remove_video } from "../../api/video";
import playIcon from "../../images/play_icon.png";
import "./index.scss";

function VideoList({ videoList, fetchMoreData, hasMore, setVideoList, removal }) {
  const navigate = useNavigate();

  const handleRemove = (id) => {
    let data = {
      video_id: id,
    };
    remove_video(data).then((res) => {
      if (res.status === 200) {
        // setVideoList(res.data.video_data);
        let video_arr = videoList.filter((video) => video.id !== id);
        setVideoList([...video_arr]);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className="row">
      <InfiniteScroll
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          overflow: "hidden",
          justifyContent: "center",
        }}
        dataLength={videoList?.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div style={{ width: "100%", textAlign: "center", color: "white" }}>
            <h5>Loading...</h5>
          </div>
        }
      >
        {videoList?.map((each, index) => {
          return (
            <div
              className={`col-xxl-4 d-flex justify-content-center`}
              key={index}
            >
              <div className="video-item">
                <div className="video-layout">
                  <ReactPlayer
                    style={{ button: { display: "none" }, background: "white" }}
                    className="react-player"
                    url={each.url}
                    height="309px"
                    width="416px"
                    playIcon={<img src={playIcon} alt="" />}
                    onClick={() => navigate(`/view/${each.id}`)} 
                    light={true}
                  ></ReactPlayer>
                  {removal && <SlClose className="video-delete-btn" onClick={() => handleRemove(each.id)} />}
                </div>
                <div className="video-content">
                  <div className="video-name">{each.name}</div>
                  <div className="d-flex justify-content-between">
                    <div className="viewed-count">{each.count} Views</div>
                    <div className="video-rating"></div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default VideoList;
