import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get_videos } from "../../api/video";
import VideoListMobile from "../VideoListMobile/videoListMobile";
import "./index.scss";

function LandingContentMobile() {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    get_videos(-1, -1).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  return (
    <div className="landing-content-mobile">
      <div className="landing-content-header-mobile">All Videos</div>
      <VideoListMobile videoList={videoList} setVideoList={setVideoList} removal={false} />
    </div>
  );
}

export default LandingContentMobile;
