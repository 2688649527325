import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashboardPart from "../../components/dashboard/dashboardPart";
import ProfilePart from "../../components/dashboard/profilePart";
import { get_user } from "../../api/video";
import "./index.scss";
import LoadingScreen from "../../components/common/loading";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard.loading);

  useEffect(() => {
    get_user().then((res) => {
      if (res.status === 200) {
        dispatch({ type: "SET_USER_DATA", payload: res.data });
        dispatch({ type: "SET_LOADING", payload: false });
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [loading]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="d-flex">
      <DashboardPart />
      <ProfilePart />
    </div>
  );
}

export default Dashboard;