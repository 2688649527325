import React from "react";
import { useNavigate } from "react-router";
import { FiYoutube, FiInstagram } from "react-icons/fi";
import { FaTiktok } from "react-icons/fa";

function LandingFooter() {
  const navigate = useNavigate();

  return (
    <div className="landing-footer row">
      <div className="col">
        <div className="landing-footer-text1">Meta prompter</div>
        <div className="landing-footer-text3">Create and watch connections.</div>
      </div>
      <div className="col">
        <div className="landing-footer-text1">Company</div>
        <a className="landing-footer-text3" href="mailto:ivs@metaprompter.com">
          Email us
        </a>
        <div className="landing-footer-text3">
          Read our <a className="blog-text" href={"https://blog.metaprompter.com/"}>blog</a>
        </div>
        <div
          className="landing-footer-text3"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy policy
        </div>
        <div className="landing-footer-text3" onClick={() => navigate("/faq")}>
          Terms and conditions
        </div>
      </div>
      <div className="col">
        <div className="landing-footer-text1 text-end">Connect with us</div>
        <div className="text-end">
          <a
            className="social-btn"
            href="https://www.instagram.com/metaprompter"
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram className="me-2 fs-4" />
          </a>
          <a
            className="social-btn"
            href="https://www.tiktok.com/@metaprompter"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok className="me-2 fs-4" />
          </a>
          <a
            className="social-btn"
            href="https://www.youtube.com/@metaprompter"
            target="_blank"
            rel="noreferrer"
          >
            <FiYoutube className="me-2 fs-4" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandingFooter;
