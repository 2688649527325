import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/auth";
import Header from "../components/header";
import LandingFooter from "../components/landing/landingFooter";
import Footer from "../components/footer";
import HeaderMobile from "../components/headerMobile";
import FooterControlMobile from "../components/footerControlMobile";

function PrivateRoute({ children }) {
  const { authTokens } = useAuth();
  const location = useLocation();
  const { pathname } = location;

  return authTokens ? (
    <>
      {isMobile ? <HeaderMobile /> : <Header />}
      {children}
      <LandingFooter />
      <Footer />
      {isMobile && pathname !== '/edit/0' && <FooterControlMobile />}
    </>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
