import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { get_user } from "../../api/video";
import LoadingScreen from "../../components/common/loading";
import AccountManageMobile from "../../components/dashboardMobile/accountManageMobile";
import MyVideoMobile from "../../components/dashboardMobile/myVideoMobile";
import "./index.scss";

function DashboardMobile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard.loading);
  const userData = useSelector((state) => state.dashboard.userData);

  useEffect(() => {
    get_user().then((res) => {
      if (res.status === 200) {
        dispatch({ type: "SET_USER_DATA", payload: res.data });
        dispatch({ type: "SET_LOADING", payload: false });
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [loading]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <div className="dashboard-layout-mobile">
      <div className="dashboard-header-mobile">Dashboard</div>
      <div className="dashboard-profile-content">
        <img
          className="dashboard-profile-img"
          src={userData?.user?.profile_img}
          alt=""
        />
        <div className="dashboard-profile-text">
          <div className="dashboard-profile-name-mobile">
            {userData?.user?.name}
          </div>
          <div className="dashboard-profile-detail-mobile">
            {userData?.my_information?.video_count} Videos
          </div>
          <div className="dashboard-profile-detail-mobile">
            {userData?.my_information?.prompt_count} Prompters
          </div>
          <div className="dashboard-profile-detail-mobile">
            {userData?.my_information?.service_count} Services
          </div>
        </div>
      </div>
      <AccountManageMobile />
      <MyVideoMobile />
    </div>
  );
}

export default DashboardMobile;
