import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { AuthContext } from "./context/auth";
import Landing from "./pages/landing";
import AllVideo from "./pages/allVideo";
import Edit from "./pages/edit";
import View from "./pages/view";
import AdminLogin from "./pages/login/adminLogin";
import Dashboard from "./pages/dashboard";
import PrivateRoute from "./routes/privateRoute";
import BuyCredit from "./pages/buyCredit";
import AdminDashBoard from "./pages/admin/dashboard";
import PublicRoute from "./routes/publicRoute";
import PrivacyPolicy from "./pages/privacy";
import FAQ from "./pages/faq";
import LandingMobile from "./pages/landingMobile";
import DashboardMobile from "./pages/dashboardMobile";
import ViewMobile from "./pages/viewMobile";
import EditMobile from "./pages/editMobile";
import DeletedVideo from "./pages/deletedVideo";
import BuyCreditMobile from "./pages/buyCreditMobile";
import PrivacyPolicyMobile from "./pages/privacyMobile";
import FAQMobile from "./pages/faqMobile";
import AllVideoMobile from "./pages/allVideoMobile";
import "./App.scss";

const App = () => {
  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={isMobile ? <LandingMobile /> : <Landing />}
          />
          <Route
            exact
            path="/all-video"
            element={
              <PublicRoute>
                {isMobile ? <AllVideoMobile /> : <AllVideo />}
              </PublicRoute>
            }
          />
          <Route
            exacts
            path="/edit/:videoId"
            element={
              <PrivateRoute>
                {isMobile ? <EditMobile /> : <Edit />}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/deleted_video"
            element={
              <PrivateRoute>
                <DeletedVideo />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/view/:videoId"
            element={
              <PublicRoute>{isMobile ? <ViewMobile /> : <View />}</PublicRoute>
            }
          />
          <Route exact path="/login" element={<AdminLogin />} />
          <Route exact path="/signup" element={<AdminLogin />} />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                {isMobile ? <DashboardMobile /> : <Dashboard />}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/buy-credit"
            element={
              <PrivateRoute>
                {isMobile ? <BuyCreditMobile /> : <BuyCredit />}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admin-dashboard"
            element={
              <PrivateRoute>
                <AdminDashBoard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
              <PublicRoute>
                {isMobile ? <PrivacyPolicyMobile /> : <PrivacyPolicy />}
              </PublicRoute>
            }
          />
          <Route
            exact
            path="/faq"
            element={
              <PublicRoute>
                {isMobile ? <FAQMobile /> : <FAQ />}
              </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
