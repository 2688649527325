import GeneralManagement from "../../../components/admin/generalManagement";
import "./index.scss";

function AdminDashBoard() {
  return (
    <div className="admin-dashboard">
      <div className="d-flex justify-content-between">
        <div className="dashboard-header">Welcome, Admin</div>
        {/* <div className="d-flex">
          <div>
            <div className="header-data">1367</div>
            <div className="header-description">New videos today</div>
          </div>
          <div>
            <div className="header-data">37</div>
            <div className="header-description">New users joined this month</div>
          </div>
          <div>
            <div className="header-data">45</div>
            <div className="header-description">Pending requests</div>
          </div>
        </div> */}
      </div>
      <GeneralManagement />
      {/* <EditWordContent /> */}
    </div>
  );
}

export default AdminDashBoard;
