import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import ReactPlayer from "react-player";
import EditVideoName from "../../components/EditVideo/editVideoName";
import PrompterList from "../../components/EditVideo/prompterList";
import RelatedList from "../../components/videoList/relatedList";
import PrompterForm from "../../components/EditVideo/prompterForm";
import MediaUploadModal from "../../components/Modals/mediaUploadModal";
import EditNameModal from "../../components/Modals/editNameModal";
import StepBar from "../../components/EditVideo/stepBar";
import VideoUpload from "../../components/EditVideo/videoUpload";
import PublishSucess from "../../components/EditVideo/publishSuccess";
import {
  get_video_by_id,
  check_record,
  publish_by_file,
} from "../../api/video";
import { add_sort_records } from "../../modules/asset";
import loadingGif from "../../images/loading.gif";
import AlertModal from "../../components/Modals/alertModal";
import editBg from "../../images/edit-bg.jpg";
import "./index.scss";

function Edit() {
  const params = useParams();
  const navigate = useNavigate();
  const tabLabels = ["Upload Video", "Add Prompters", "Publish"];
  const player = useRef(null);
  const [tab, setTab] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [file, setFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [videoName, setVideoName] = useState("Video Title");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [alert, setAlert] = useState({ content: "", isErr: false });
  const [publishedId, setPublishedId] = useState("");
  const maxTime = parseInt(process.env.REACT_APP_VIDEO_MAX_TIME);

  useEffect(() => {
    if (params.videoId === "0") setTab(0);
    else {
      get_video_by_id(params.videoId).then((res) => {
        if (res.status === 200) {
          setTab(1);
          setVideoLink(res.data.link);
          setRecords(res.data.records);
          setVideoName(res.data.name);
          setLoading(false);
        } else {
          if (res.data.message === "Token is invalid or has expired!") {
            localStorage.removeItem("tokens");
            navigate("/login");
            window.location.reload();
          }
        }
      });
    }
  }, []);

  // Function
  const mediaToggle = () => {
    setUploadModal(!uploadModal);
  };
  const editNameToggle = () => {
    setEditNameModal(!editNameModal);
  };
  const onProgress = () => {
    let current_time = parseInt(player.current.getCurrentTime());
    if (current_time > maxTime) {
      player.current.seekTo(maxTime);
      setTime(maxTime);
    } else setTime(current_time);
  };
  const handleTime = (time) => {
    setTime(time);
    player.current.seekTo(time);
  };
  const handleRecord = (record) => {
    setName(record.name);
    setLink(record.link);
    setTime(record.time_slot);
    player.current.seekTo(record.time_slot);
  };
  const uploadFile = (file) => {
    setFile(file);
    setVideoLink(URL.createObjectURL(file));
    setVideoName(file.name.substring(0, file.name.lastIndexOf(".")));
    setTab(1);
    setUploadModal(false);
  };
  const uploadUrl = (url) => {
    setVideoLink(url);
    setTab(1);
    setUploadModal(false);
  };

  // API
  const addRecord = () => {
    let reqBody = {
      url: link,
    };
    check_record(reqBody).then((res) => {
      if (res.status === 200) {
        let new_record = {
          time_slot: time,
          name: name,
          ...res.data,
        };
        let new_records = add_sort_records(records, new_record);
        setRecords(new_records);
        setName("");
        setLink("");
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        } else {
          setAlert({ content: res.data, isErr: true });
        }
      }
    });
  };
  const removeRecord = (index) => {
    let temp_records = [...records];
    temp_records.splice(index, 1);
    setRecords(temp_records);
  };
  const handlePublish = () => {
    let req_body = new FormData();
    if (file) {
      req_body.append("file", file);
      let req_data = {
        id: params.videoId,
        name: videoName,
        records: records,
      };
      req_body.append("data", JSON.stringify(req_data));
    } else {
      let req_data = {
        id: params.videoId,
        url: videoLink,
        name: videoName,
        records: records,
      };
      req_body.append("data", JSON.stringify(req_data));
    }
    setLoading(true);
    publish_by_file(req_body).then((res) => {
      if (res.status === 200) {
        setPublishedId(res.data.id);
        setVideoLink(res.data.url);
        setLoading(false);
        setTab(2);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        } else {
          setAlert({ content: res.data, isErr: true });
          setLoading(false);
        }
      }
    });
  };
  // const removeVideo = () => {
  //   setTab(0);
  //   setVideoName("Video Title");
  //   setRecords([]);
  //   setName("");
  //   setLink("");
  // };

  return (
    <div className="edit-page" style={{ backgroundImage: tab === 0 && `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(${editBg})` }}>
      <StepBar tab={tab} tabLabels={tabLabels} />
      <div className="edit-header">
        {tab === 2 ? "Congratulations!" : (tab === 0 && "New Video")}
      </div>
      {tab === 2 && (
        <div className="edit-header">Your video is now published.</div>
      )}
      {tab === 0 && (
        <VideoUpload uploadFile={uploadFile} uploadUrl={uploadUrl} />
      )}
      {tab === 1 &&
        (loading ? (
          <div className="loading-screen">
            <img className="page-loading-gif" src={loadingGif} alt="" />
          </div>
        ) : (
          <div>
            <div className="edit-content">
              <div>
                <div className="d-flex mb-3">
                  <EditVideoName
                    videoName={videoName}
                    openEditNameModal={editNameToggle}
                    tab={tab}
                  />
                  <div className="edit-video-text">
                    Add a new prompter using the panel below. Set the time, name of<br /> the prompter and  a link to open once you click the prompter.
                  </div>
                </div>
                <div className="d-flex">
                  <ReactPlayer
                    ref={player}
                    controls
                    width={836}
                    height={486}
                    className="d-inline-block"
                    url={videoLink}
                    progressInterval={100}
                    onProgress={onProgress}
                    playing={playing}
                    onPlay={() => setPlaying(true)}
                    config={{
                      youtube: {
                        playerVars: {
                          start: 0,
                          end: maxTime,
                        },
                      },
                    }}
                  ></ReactPlayer>
                  <PrompterForm
                    name={name}
                    link={link}
                    setName={setName}
                    setLink={setLink}
                    time={time}
                    addPrompter={addRecord}
                    handleTime={handleTime}
                  />
                </div>
              </div>
            </div>
            <PrompterList
              list={records}
              removeRecord={removeRecord}
              handleRecord={handleRecord}
            />
            <div className="text-center pb-5">
              <button
                className="btn color-bordered-btn delete-btn"
                onClick={() => navigate('/deleted_video')}
              >
                Delete
              </button>
              <button
                className="btn fullwidth-btn publish-btn"
                onClick={handlePublish}
              >
                Publish Video
              </button>
            </div>
          </div>
        ))}
      {tab === 2 && (
        <PublishSucess
          videoLink={videoLink}
          videoName={videoName}
          publishedId={publishedId}
        />
      )}
      {tab === 2 && <RelatedList />}
      <MediaUploadModal
        open={uploadModal}
        onClose={mediaToggle}
        uploadFile={uploadFile}
        uploadUrl={uploadUrl}
      />
      <EditNameModal
        open={editNameModal}
        onClose={editNameToggle}
        videoName={videoName}
        setVideoName={setVideoName}
      />
      <AlertModal
        alert={alert}
        closeAlert={() => setAlert({ content: "", isErr: false })}
      />
    </div>
  );
}

export default Edit;
