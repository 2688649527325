import React from "react";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import playIcon from "../../images/play_icon.png";
import "./index.scss";

function LandingDescription() {
  const navigate = useNavigate();

  return (
    <div className="landing-description">
      <div className="text-header">How it works?</div>
      <div className="desc-content">
        <div className="desc-video d-flex justify-content-center">
          <ReactPlayer
            controls
            width={800}
            height={450}
            playing
            url={
              "https://cicd-metaprompt.s3.eu-west-1.amazonaws.com/Intro_metapropter.mp4"
            }
            playIcon={<img src={playIcon} alt="Play" />}
            light={"https://cicd-metaprompt.s3.eu-west-1.amazonaws.com/Intro_metapropter.png"}
          />
        </div>
        <div className="desc-text">
          <div className="text-content">
            <div className="paragraph">
              The Metaprompter is a tool that allows you to insert content into
              your favorite videos.{" "}
            </div>
            <div className="paragraph">
              Thanks to its non intrusive design you can watch and interact or
              not.
            </div>
            It is easy, clear and a bit surprising.
            <div className="paragraph">
              The system is absolutely safe and spam free.
            </div>
            <div className="paragraph">
              Our team of editors curates the content and make sure you don’t
              click where you should not.
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn login-btn text-white mb-3 me-4"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            <button
              className="btn signup-btn text-white mb-3"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingDescription;
