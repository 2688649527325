import "./index.scss";

function FAQMobile() {
  return (
    <div className="faq-page-mobile">
      <div className="faq-header-mobile">FAQ</div>
      <div className="faq-text-mobile">
        <div className="text-group-mobile">FAQ</div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">Why Metaprompter? is that AI?</div>
          Sadly we had the name before the Prompter and AI explosion. For us it
          was a super powerful teleprompter as it had supercharged notes synched
          to a video
        </div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">Can I use it for free?</div> You can
          watch videos and see prompts without need of logging in. Just check it
          out and see if you like it
        </div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">How can I publish videos?</div> For
          this you need to pay. Not a subscription , just a per-video single
          pay. Via Stripe
        </div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">
            How can I see the performance of videos?
          </div>
          Once you signup you will get an admin console , from there you will
          see how your content does
        </div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">
            Why do I need to sign with Google?
          </div>
          We are a small company, the less information we store , the less at
          risk we put you as a customer. We take security very seriously but
          also need to get customers quick. Some won’t like it and we will try
          to find a better solution
        </div>
        <div className="text-group-mobile">
          <div className="text-sub-header-mobile">Aren’t prompters just banners?</div>
          Our job is to ensure users create useful and relatable content. It is
          a similar technology , with the addition of syncronization, put to the
          job of deepening your knowledge and options. It is a visual link .What
          to do with it depends on us.
        </div>
        <div className="text-group-mobile">When can I start?  You can start now!</div>
      </div>
    </div>
  );
}

export default FAQMobile;
