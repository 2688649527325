import { Modal, ModalBody } from "reactstrap";

function ConfirmModal({ confirmModal, closeModal, handleItem }) {
  return (
    <Modal
      className="remove-confirm-modal"
      isOpen={confirmModal.status}
      toggle={closeModal}
    >
      <ModalBody className="remove-confirm-modal-body">
        <div className="remove-text">
          Are you sure?
        </div>
        <div className="remove-btn-group">
          <button
            className="btn btn-primary handle-btn me-3"
            onClick={() => handleItem(confirmModal.id)}
          >
            Yes
          </button>
          <button className="btn btn-secondary handle-btn" onClick={closeModal}>
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ConfirmModal;
