import { Modal } from "reactstrap";

function AlertModal({ alert, closeAlert }) {
  return (
    <Modal isOpen={alert.content} toggle={closeAlert}>
      <div
        className={`${
          alert.isErr ? "alert-danger" : "alert-success"
        } alert  mb-0 text-center`}
        role="alert"
      >
        {alert.content}
      </div>
    </Modal>
  );
}

export default AlertModal;
