import React from "react";
// import Footer from "../../components/footer";
import bgImg from "../../images/land-bg.png";
import LandingHeaderMobile from "../../components/landingMobile/landingHeaderMobile";
import HeaderMobile from "../../components/headerMobile";
import "./index.scss";
import FooterControlMobile from "../../components/footerControlMobile";

function LandingMobile() {
  return (
    <>
      <HeaderMobile />
      <div
        className="landing-layout-mobile"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <LandingHeaderMobile />
        {/* <LandingContentMobile /> */}
        <FooterControlMobile />
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default LandingMobile;
