import { useState, useEffect } from "react";
import { CiCircleAlert } from "react-icons/ci";
import reportIcon from "../../images/report-icon.png";

function PrompterForm({
  name,
  link,
  setName,
  setLink,
  time,
  addPrompter,
  // handleTime,
}) {
  const [min, setMin] = useState("");
  const [sec, setSec] = useState("");

  useEffect(() => {
    setMin(time / 60 < 10 ? `0${parseInt(time / 60)}` : parseInt(time / 60));
    setSec(time % 60 < 10 ? `0${time % 60}` : time % 60);
  }, [time]);

  // functions
  // const handleFragment = (e, type) => {
  //   let value = e.target.value;
  //   if (check_time_fragment(value)) {
  //     if (type === "min") handleTime(parseInt(value) * 60 + parseInt(sec));
  //     else handleTime(parseInt(min) * 60 + parseInt(value));
  //   } else alert("please insert correct time");
  // };
  return (
    <div className="prompter-form">
      <div className="text-center form-text1">New Prompter</div>
      <label className="form-text2">Time <CiCircleAlert className="form-text-icon" /></label>
      <div className="upload-input text-center">
        {`${min}:${sec}`}
      </div>
      <label className="form-text2">
        Name <CiCircleAlert className="form-text-icon" />
      </label>
      <input
        type="text"
        className="form-control upload-input"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="eg: spiderman mask"
      />
      <label className="form-text2">Link <CiCircleAlert className="form-text-icon" /></label>
      <input
        type="text"
        className="form-control upload-input"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        placeholder="insert external link"
      />
      <button className="btn fullwidth-btn mt-3" onClick={addPrompter}>
        Create New Prompter
      </button>
      <div className="report-problem">
        <img src={reportIcon} alt="" />{" "}
        <span className="service-text">report problem</span>
      </div>
    </div>
  );
}

export default PrompterForm;
