import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FiSearch } from "react-icons/fi";
import { get_user_videos } from "../../api/video";
import "./index.scss";
import VideoListMobile from "../VideoListMobile/videoListMobile";

function MyVideo() {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    get_user_videos(-1, -1).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  return (
    <div className="my-video-layout-mobile">
      <div className="video-header-mobile">
        <div className="dashboard-sub-header-mobile">My videos</div>
        <FiSearch className="header-search" />
      </div>
      <div className="video-list-mobile">
        <VideoListMobile videoList={videoList} setVideoList={setVideoList} removal={true} />
      </div>
    </div>
  );
}

export default MyVideo;
