import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import logoutIcon from "../../images/logout-icon.png";
import arrowRight from "../../images/arrow-right.png";
import arrowBottom from "../../images/arrow-bottom.png";
import "./index.scss";

function ProfilePart() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.dashboard.userData);
  const [tab, setTab] = useState({
    profile: false,
    password: false,
    payment: false,
    logout: false,
  });

  // functions
  const handleTab = (list) => {
    let tempTab = { ...tab };
    tempTab[list] = !tempTab[list];
    setTab(tempTab);
  };
  const handleLogout = () => {
    localStorage.removeItem("tokens");
    navigate("/login");
    window.location.reload();
  };
  return (
    <div className="profile-layout">
      <div className="profile-name">{userData?.user?.name}</div>
      <img className="profile-img" src={userData?.user?.profile_img} alt="" />
      <div className="profile-detail">
        {userData?.my_information?.video_count} Videos
      </div>
      <div className="profile-detail">
        {userData?.my_information?.prompt_count} Prompters
      </div>
      <div className="profile-detail">
        {userData?.my_information?.service_count} Services
      </div>
      {/* <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={profileIcon} alt="" />{" "}
              <span className="card-content-header">Profile</span>
            </div>
            <div className="card-desc">Linked Social Media Accounts</div>
          </div>
          <div className="expand-card" onClick={() => handleTab("profile")}>
            <img src={tab.profile ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.profile && (
          <div className="profile-item">
            <div className="profile-item-header">
              <img src={instagramIcon} alt="" />{" "}
              <span className="ms-2">Instagram</span>
            </div>
            <div className="profile-item-content">@joshmoore</div>
            <div className="profile-item-header">
              <img src={tiktokIcon} alt="" />{" "}
              <span className="ms-2">TikTok</span>
            </div>
            <div className="profile-item-content">@joshmooretk</div>
            <div className="profile-item-header">
              <img src={twitterIcon} alt="" />{" "}
              <span className="ms-2">Twitter</span>
            </div>
            <div className="profile-item-link">Link account</div>
            <div className="profile-item-header">
              <img src={twitchIcon} alt="" />{" "}
              <span className="ms-2">Twitch</span>
            </div>
            <div className="profile-item-link">Link account</div>
          </div>
        )}
      </div> */}
      {/* <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={passwordIcon} alt="" />{" "}
              <span className="card-content-header">Password</span>
            </div>
            <div className="card-desc">Recover Password</div>
          </div>
          <div className="expand-card" onClick={() => handleTab("password")}>
            <img src={tab.password ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.password && (
          <div className="fullwidth-btn mt-4">Send recovery to email</div>
        )}
      </div> */}
      {/* <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={creditIcon} alt="" />{" "}
              <span className="card-content-header">Payment Info</span>
            </div>
            <div className="card-desc">Edit Your Card Information</div>
          </div>
          <div className="expand-card" onClick={() => handleTab("credit")}>
            <img src={tab.credit ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.credit && (
          <div className="mt-3">
            <div className="input-groups">
              <label className="form-label form-input-label">Name</label>
              <input
                type="text"
                className="form-control form-input"
                placeholder="Name"
              />
            </div>
            <div className="input-groups">
              <label className="form-label form-input-label mt-2">
                Card Number
              </label>
              <input
                type="text"
                className="form-control form-input"
                placeholder="Card Number"
              />
            </div>
            <div className="d-flex">
              <div className="input-groups mt-2 me-2">
                <label className="form-label form-input-label">Exp.Date</label>
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Exp.Date"
                />
              </div>
              <div className="input-groups mt-2 ms-2">
                <label className="form-label form-input-label">
                  Security Code
                </label>
                <input
                  type="text"
                  className="form-control form-input"
                  placeholder="Security Code"
                />
              </div>
            </div>
            <div className="fullwidth-btn mt-4">Update</div>
          </div>
        )}
      </div> */}
      <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={logoutIcon} alt="" />{" "}
              <span className="card-content-header">Log Out</span>
            </div>
            <div className="card-desc">Log out of your current profile</div>
          </div>
          <div className="expand-card" onClick={() => handleTab("logout")}>
            <img src={tab.logout ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.logout && (
          <div className="fullwidth-btn mt-4" onClick={handleLogout}>
            Log Out
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilePart;
