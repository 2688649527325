import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useAuth } from "../../context/auth";
import { googleLogin, googleSignup } from "../../api/auth";
import { useGoogleLogin } from '@react-oauth/google';
import { isMobile } from "react-device-detect";
import bgImg from "../../images/land-bg.png";
import googleIcon from "../../images/google-icon.png";
import Header from "../../components/header";
import LandingFooter from "../../components/landing/landingFooter";
import Footer from "../../components/footer";
import HeaderMobile from "../../components/headerMobile";
import "./index.scss";

function AdminLogin() {
  const navigate = useNavigate();
  const locations = useLocation();
  const [loginType, setLoginType] = useState(null);
  const [alert, setAlert] = useState("");
  const { setAuthTokens } = useAuth();

  useEffect(() => {
    setLoginType(locations.pathname === "/login" ? true : false);
  }, [locations.pathname]);

  const handleAuth = useGoogleLogin({
    onSuccess: tokenResponse => {
      let req_data = {
        token: tokenResponse.access_token,
      };

      if (loginType) googleLogin(req_data).then((res) => {
        if (res.status === 200) {
          let auth_data = res.data;
          setAuthTokens(auth_data);
          navigate("/dashboard");
        } else {
          setAlert(res.data.error);
          setTimeout(() => setAlert(""), 2000);
        }
      })
      else googleSignup(req_data).then((res) => {
        if (res.status === 200) {
          let auth_data = res.data;
          setAuthTokens(auth_data);
          navigate("/dashboard");
        } else {
          setAlert(res.data.error);
          setTimeout(() => setAlert(""), 2000);
        }
      })
    },
  });

  return (
    <>
      {isMobile ? <HeaderMobile /> : <Header />}
      <div className="admin-login" style={{ backgroundImage: `url(${bgImg})` }}>
        <div>
          <div className="login-header">Welcome to MetaPrompter</div>
          <div className="login-alert">{alert}</div>
          {!loginType && <div className="login-header-text1">Create videos for free from your profile.</div>}
          {!loginType && <div className="login-header-text2">No credit card required.</div>}
          <div><img className="google-icon" src={googleIcon} alt="" /><span className="sign-text" onClick={handleAuth}>{loginType ? "Login using Google" : "Signup using Google"}</span></div>
          <div className="sign-text1">Registration via email coming soon.</div>
          <div className="sign-text2">{loginType ? "Don't you have an account?" : "Do you have an account?"}</div>
          <div><span className="sign-text" onClick={() => navigate(`${loginType ? "/signup" : "/login"}`)}>{loginType ? "Sign Up Here" : "Switch to Login Here"}</span></div>
        </div>
      </div>
      <LandingFooter />
      <Footer />
    </>
  );
}

export default AdminLogin;
