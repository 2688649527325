import { AiFillCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { makeTime } from "../../modules/asset";

function PrompterListMobile({ list, removeRecord, handleRecord, setShowPrompterList }) {
  return (
    <div className="prompter-layout-mobile">
      <div className="list-layout-mobile">
        {list &&
          list.map((each, index) => (
            <div className="prompter-card-mobile" key={index}>
              <AiFillCloseCircle
                  className="prompter-close-icon-mobile"
                  onClick={() => removeRecord(index)}
                />
              <div
                className="prompter-card-content-mobile"
                onClick={() => handleRecord(each)}
              >
                <div className="prompter-card-time-mobile">
                  {makeTime(each.time_slot)}
                </div>
                <div
                  className="prompter-card-name-mobile"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={each.name}
                >
                  {each.name}
                </div>
                <img
                  className="prompter-card-icon-mobile"
                  src={each.icon}
                  alt=""
                />
              </div>
              <div className="prompter-card-footer-mobile">{each.service}</div>
            </div>
          ))}
        <div className="add-prompter-card-mobile" onClick={() => setShowPrompterList(false)}>
          <div className="d-flex flex-column align-items-center">
            <div className="add-prompter-icon-mobile">
              <AiOutlinePlus />
            </div>
            <div className="add-prompter-text-mobile">Add Prompter</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrompterListMobile;
