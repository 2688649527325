import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ReactPlayer from "react-player";
import playIcon from "../../images/play_icon.png";
import { related_videos } from "../../api/video";
import "./index.scss";

function RelatedList() {
  const navigate = useNavigate();
  const params = useParams();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    related_videos(params.videoId).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate, params.videoId]);

  return (
    <>
      <div className="related-header">Related Videos</div>
      <div className="d-flex overflow-hidden">
        {videoList.map((each, index) => (
          <div className="" key={index}>
            <div
              className="video-item"
            >
              <ReactPlayer
                style={{ button: { display: "none" } }}
                className="react-player related-video"
                url={each.url}
                height={228}
                width={304}
                playIcon={<img src={playIcon} alt="" />}
                onClick={() => navigate(`/view/${each.id}`)}
                light={true}
              ></ReactPlayer>
              <div className="related-video-name">{each.name}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default RelatedList;
