function CreatorProfile({ user }) {
  return (
    <div className="creator-profile">
      <div className="profile-header">About the Creator</div>
      <div className="profile-content">
        <div className="w-50 d-flex flex-column align-items-center">
          <div className="profile-name">{user?.name}</div>
          {/* <div className="prompters-cnt">17 Prompters</div> */}
        </div>
        <div className="w-50">
          <img className="profile-img" src={user.profile_img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default CreatorProfile;
