import { makeTime, check_video_time_list } from "../../modules/asset";
import "./index.scss";

function PrompterBoard({ handleRecord, video, start, stay, time, hiddenTime }) {
  return (
    <div className="prompter-board">
      <div className="prompter-header">Prompters</div>
      {video?.records?.map((each, index) => {
        if (
          start &&
          !stay &&
          check_video_time_list(time, each.time_slot, hiddenTime)
        ) {
          return (
            <div
              className={
                time < parseInt(each.time_slot)
                  ? "event-ticket up-comming"
                  : "event-ticket"
              }
              key={index}
              onClick={() => handleRecord(each)}
            >
              <div className="ticket-body">
                <div className="ticket-time">{makeTime(each.time_slot)}</div>
                <div className="publish-name">{each.name}</div>
                <img className="ticket-abb" src={each.icon} alt="" />
              </div>
              <div className="ticket-footer">
                <span>{each.service}</span>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default PrompterBoard;
