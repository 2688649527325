import React from "react";
import loadingGif from "../../images/loading.gif";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <img className="page-loading-gif" src={loadingGif} alt="" />
    </div>
  );
}

export default LoadingScreen;
