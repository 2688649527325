import { useState, useEffect } from "react";
import { check_time_fragment } from "../../modules/asset";
import { FaChevronRight } from "react-icons/fa6";

function PrompterFormMobile({
  name,
  link,
  setName,
  setLink,
  time,
  addPrompter,
  handleTime,
}) {
  const [min, setMin] = useState("");
  const [sec, setSec] = useState("");

  useEffect(() => {
    setMin(time / 60 < 10 ? `0${parseInt(time / 60)}` : parseInt(time / 60));
    setSec(time % 60 < 10 ? `0${time % 60}` : time % 60);
  }, [time]);

  // functions
  const handleFragment = (e, type) => {
    let value = e.target.value;
    if (check_time_fragment(value)) {
      if (type === "min") handleTime(parseInt(value) * 60 + parseInt(sec));
      else handleTime(parseInt(min) * 60 + parseInt(value));
    } else alert("please insert correct time");
  };
  return (
    <div className="prompter-form-mobile">
      <hr />
      <div className="d-flex align-items-center mt-3">
        <label className="form-text2-mobile">
          Text
        </label>
        <input
          type="text"
          className="form-control upload-input-mobile"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="eg: spiderman mask"
        />
      </div>
      <div className="d-flex align-items-center mt-3">
        <label className="form-text2-mobile">
          Link
        </label>
        <input
          type="text"
          className={`form-control upload-input-mobile`}
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="insert external link"
        />
      </div>
      <div className="d-flex mt-3">
        <button className="btn color-bordered-btn-mobile w-50 me-2">
          <span>Help</span>
          <FaChevronRight />
        </button>
        <button className="btn fullwidth-btn-mobile" onClick={addPrompter}>
          Create Prompter
        </button>
      </div>

    </div>
  );
}

export default PrompterFormMobile;
