import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { FiAlertTriangle, FiSearch, FiCopy } from "react-icons/fi";
import RelatedList from "../../components/videoList/relatedList";
import PrompterBoard from "../../components/viewVideo/prompterBoard";
import CreatorProfile from "../../components/viewVideo/creatorProfile";
import { get_video_plus_by_id, plus_record_count } from "../../api/video";
import { unsecuredCopyToClipboard } from "../../modules/asset";
import "./index.scss";

function View() {
  const params = useParams();
  const copy_link = `${process.env.REACT_APP_URL}view/${params.videoId}`;
  const stay = false;
  const hiddenTime = 5;
  const navigate = useNavigate();
  const player = useRef(null);
  const [video, setVideo] = useState(null);
  const [user, setUser] = useState(null);
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copyAlert, setCopyAlert] = useState(false);

  useEffect(() => {
    getVideoPlusById();
  }, [params]);

  // Function
  const getVideoPlusById = () => {
    get_video_plus_by_id(params.videoId).then((res) => {
      if (res.status === 200) {
        setVideo(res.data.video_data);
        setUser(res.data?.user_data);
        setLoading(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };
  const onProgress = () => {
    let current_time = parseInt(player.current.getCurrentTime());
    setTime(current_time);
  };
  const handleCopy = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(copy_link);
      setCopyAlert(true);
    } else {
      unsecuredCopyToClipboard(copy_link);
      setCopyAlert(true);
    }
  };
  const handleRecord = (data) => {
    window.open(data.url, "_blank");
    setPlaying(false);
    plus_record_count(data.id).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <>
      <div className="view-page">
        <div className="view-header row ms-0 me-0">
          <div className="video-title col-8">{video?.name}</div>
          <div className="video-search col-4">
            <FiSearch />
            Search
          </div>
        </div>
        <div className="view-content row ms-0 me-0">
          <div className="col-8 d-flex justify-content-center">
            <div className="video-wrapper">
              <ReactPlayer
                ref={player}
                controls
                width={863}
                height={600}
                className="d-inline-block align-top"
                url={video?.url}
                progressInterval={100}
                onProgress={onProgress}
                onStart={() => setStart(true)}
                playing={playing}
                onPlay={() => setPlaying(true)}
              ></ReactPlayer>
              {/* <div className="share-video">
                <div className="share-video-text">Share this video</div>
                <div className="d-flex justify-content-center">
                  <div className="social-icons-layout">
                    <SocialButtonGroup />
                  </div>
                </div>
              </div> */}
              <div className="copy-link">
                <div className="copy-link-text">Share this video</div>
                <div className="d-flex mt-3 mb-4">
                  <div className="copy-link-url">
                    <span className="copy-link-url-text">{copy_link}</span>
                    <FiCopy />
                  </div>
                  <button className="btn colored-btn" onClick={handleCopy}>
                    {copyAlert ? "Copied" : "Copy Link"}
                  </button>
                </div>
              </div>
              <div className="report-problem">
                <FiAlertTriangle />{" "}
                <span className="report-text">Report Problem</span>
              </div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <div>
              <PrompterBoard
                handleRecord={handleRecord}
                video={video}
                time={time}
                start={start}
                stay={stay}
                hiddenTime={hiddenTime}
              />
              {user && <CreatorProfile user={user} />}
            </div>
          </div>
        </div>
        <RelatedList />
      </div>
    </>
  );
}

export default View;
