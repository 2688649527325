import { useNavigate } from "react-router";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import playIcon from "../../images/play_icon.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
};

function VideoCarousel({ videos }) {
    const navigate = useNavigate();
    return <div>
        <Slider {...settings}>
            {videos.map((each, index) => {
                return (
                    <div
                        key={index}
                        className="video-item d-flex justify-content-center"
                        onClick={() => navigate(`/view/${each.id}`)}
                    >
                        <ReactPlayer
                            style={{ button: { display: "none" }, background: "white" }}
                            className="react-player"
                            url={each.url}
                            height="675px"
                            width="1200px"
                            playIcon={<img src={playIcon} alt="" />}
                            light={true}
                        ></ReactPlayer>
                        <div className="video-carousel-name">
                            <span>{each.name}</span>
                        </div>
                    </div>
                );
            })}
        </Slider>
    </div>
}

export default VideoCarousel;