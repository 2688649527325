import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { FiCopy } from "react-icons/fi";
import PrompterBoardMobile from "../../components/viewVideoMobile/prompterBoardMobile";
import { get_video_plus_by_id, plus_record_count } from "../../api/video";
import { unsecuredCopyToClipboard } from "../../modules/asset";
import "./index.scss";

function ViewMobile() {
  const params = useParams();
  const copy_link = `${process.env.REACT_APP_URL}view/${params.videoId}`;
  const stay = false;
  const hiddenTime = 5;
  const navigate = useNavigate();
  const player = useRef(null);
  const [video, setVideo] = useState(null);
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [copyAlert, setCopyAlert] = useState(false);

  useEffect(() => {
    getVideoPlusById();
  }, [params]);

  // Function
  const getVideoPlusById = () => {
    get_video_plus_by_id(params.videoId).then((res) => {
      if (res.status === 200) {
        setVideo(res.data.video_data);
        setLoading(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };
  const onProgress = () => {
    let current_time = parseInt(player.current.getCurrentTime());
    setTime(current_time);
  };
  const handleCopy = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(copy_link);
      setCopyAlert(true);
    } else {
      unsecuredCopyToClipboard(copy_link);
      setCopyAlert(true);
    }
  };
  const handleRecord = (data) => {
    window.open(data.url, "_blank");
    setPlaying(false);
    plus_record_count(data.id).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className="view-layout-mobile">
      <ReactPlayer
        ref={player}
        controls
        width={"100%"}
        height={190}
        className="d-inline-block align-top"
        url={video?.url}
        progressInterval={100}
        onProgress={onProgress}
        onStart={() => setStart(true)}
        playing={playing}
        onPlay={() => setPlaying(true)}
      ></ReactPlayer>
      <div className="view-content-layout-mobile">
        <div
          className="d-flex align-items-center justify-content-between pt-2"
        >
          <div className="video-name-mobile">{video?.name}</div>
          <div className="copy-icon-layout-mobile" onClick={handleCopy}>
            <FiCopy className="copy-icon-mobile" />
            <div className="copy-icon-text-mobile">{copyAlert ? "Copied" : "Copy"}</div>
          </div>
        </div>
        <PrompterBoardMobile
          handleRecord={handleRecord}
          video={video}
          time={time}
          start={start}
          stay={stay}
          hiddenTime={hiddenTime}
        />
      </div>
    </div>
  );
}

export default ViewMobile;
