import axios from "axios";

function login(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}login`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

function googleLogin(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}google-login`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

function googleSignup(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}google-signup`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

function customerLogin(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}customer_login`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

function signUp(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}signup`,
    headers: { "Content-Type": "application/json" },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

export { login, googleLogin, googleSignup, customerLogin, signUp };
