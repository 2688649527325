import React from "react";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import { FiPlusCircle, FiUser } from "react-icons/fi";
import "./index.scss";

function DeletedVideo() {
  const navigate = useNavigate();

  return (
    <div className="deleted-video-layout">
      <div className="deleted-video-content">
        <div className="deleted-video-text">
          Your Video has been deleted.
        </div>
        <button
          className={`btn ${isMobile ? "fullwidth-btn-mobile" : "fullwidth-btn"
            } mb-4 d-flex align-items-center justify-content-center`}
          onClick={() => navigate("/edit/0")}
        >
          <FiPlusCircle className="me-2" />
          <span>Create new video</span>
        </button>
        <button
          className={`btn ${isMobile
              ? "fullwidth-bordered-btn-mobile"
              : "color-bordered-btn w-100"
            } d-flex align-items-center justify-content-center`}
          onClick={() => navigate("/dashboard")}
        >
          <FiUser className="me-2" />
          <span>Return to Dashboard</span>
        </button>
      </div>
    </div>
  );
}

export default DeletedVideo;
