import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import VideoList from "../../components/videoList/videoList";
import { BiSearch } from "react-icons/bi";
import { get_videos } from "../../api/video";
import VideoCarousel from "../../components/videoList/videoCarousel";
import "./index.scss";
import { selectRandomVideos } from "../../modules/asset";

function AllVideo() {
  const navigate = useNavigate();
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(9);
  const [videoList, setVideoList] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    get_videos(firstIndex, lastIndex).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
        setListLength(res.data.total_cnt);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  const fetchMoreData = () => {
    setFirstIndex(lastIndex);
    setLastIndex(2 * lastIndex - firstIndex);
    get_videos(lastIndex, 2 * lastIndex - firstIndex).then((res) => {
      if (res.status === 200) {
        let tempList = [...videoList];
        tempList.push(...res.data.video_data);
        setVideoList(tempList);
        if (tempList.length < listLength) setHasMore(true);
        else setHasMore(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className="all-video-page">
      <div className="search-input-layout">
        <div className="input-group search-input-box">
          <input type="text" className="form-control" placeholder="Search" />
          <span className="input-group-text"><BiSearch /></span>
        </div>
        <span className="all-video-text">
          Sort by: Recent
        </span>
      </div>
      <VideoCarousel videos={selectRandomVideos(videoList, videoList.length)} />
      <div className="all-video-recommended">
        <span>Recommended</span>
      </div>
      <VideoList
        column={4}
        videoList={videoList}
        setVideoList={setVideoList}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        removal={false}
      />
    </div>
  );
}

export default AllVideo;
