import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import LandingContent from "../../components/landing/landingContent";
import LandingFooter from "../../components/landing/landingFooter";
import LandingHeader from "../../components/landing/landingHeader";
import Sign from "../../components/landing/landingDescription";
import "./index.scss";

function Landing() {
  return (
    <>
      <Header />
      <div className="landing-layout">
        <LandingHeader />
        <Sign />
        <LandingContent />
        <LandingFooter />
        <Footer />
      </div>
    </>

  );
}

export default Landing;
