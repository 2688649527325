import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import cameraIcon from "../../images/camera-icon.png";
import profileIcon from "../../images/profile-icon.png";
import cloudIcon from "../../images/cloud-icon.png";
import arrowRight from "../../images/arrow-right.png";
import arrowBottom from "../../images/arrow-bottom.png";
import PerformanceChart from "./performanceChart";
import "./index.scss";

function DashboardContent() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.dashboard.userData);
  const [tab, setTab] = useState({
    serviceList: false,
    service: false,
    performance: false,
    credits: false,
  });

  // functions
  const handleTab = (list) => {
    let tempTab = { ...tab };
    tempTab[list] = !tempTab[list];
    setTab(tempTab);
  };

  return (
    <div className="d-flex">
      <div className="card-row pe-3 w-100">
        <div className="dashboard-option-card">
          <div className="d-flex align-items-center justify-content-between">
            <div className="card-content">
              <div className="d-flex align-items-center">
                <img src={cameraIcon} alt="" />{" "}
                <span className="card-content-header">Create a New Video</span>
              </div>
              <div className="card-desc">
                You will be redirected to the “Upload Video” page.
              </div>
            </div>
            <div className="expand-card" onClick={() => navigate("/edit/0")}>
              <img src={arrowRight} alt="" />
            </div>
          </div>
        </div>
        <div className="dashboard-option-card">
          <div className="d-flex align-items-center justify-content-between">
            <div className="card-content">
              <div className="d-flex align-items-center">
                <img src={cloudIcon} alt="" />{" "}
                <span className="card-content-header">Credits</span>
              </div>
              <div className="card-desc">
                Buy Credits and review credit information
              </div>
            </div>
            <div className="expand-card" onClick={() => handleTab("credits")}>
              <img src={tab.credits ? arrowBottom : arrowRight} alt="" />
            </div>
          </div>
          {tab.credits && (
            <div className="credits-content">
              <div className="dashboard-card-list">
                <span>Available credits</span>
                <span>{userData?.user?.credit}</span>
              </div>
              <div
                className="fullwidth-btn mt-4"
                onClick={() => navigate("/buy-credit")}
              >
                Purchase More Credits
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card-row ps-3 w-100">
        <div className="dashboard-option-card">
          <div className="d-flex align-items-center justify-content-between">
            <div className="card-content">
              <div className="d-flex align-items-center">
                <img src={profileIcon} alt="" />{" "}
                <span className="card-content-header">Check Performance</span>
              </div>
              <div className="card-desc">
                Review number of videos, prompters and services
              </div>
            </div>
            <div
              className="expand-card"
              onClick={() => handleTab("performance")}
            >
              <img src={tab.performance ? arrowBottom : arrowRight} alt="" />
            </div>
          </div>
          {tab.performance && (
            <div className="performance-content">
              <div className="dashboard-card-list">
                <span>Uploaded Videos</span>
                <span>{userData?.checkperformance?.FinishedVideos}</span>
              </div>
              <div className="dashboard-card-list">
                <span>Prompters Seen</span>
                <span>
                  {userData?.checkperformance?.ClickedPrompters?.totalLogins}
                </span>
              </div>
              <div className="dashboard-card-list">
                <span>Views</span>
                <span>
                  {userData?.checkperformance?.VideosViewed?.totalLogins}
                </span>
              </div>
              <PerformanceChart />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
