import { FiEdit } from "react-icons/fi";
import "./index.scss";

function EditVideoNameMobile({ videoName, openEditNameModal, tab }) {
  return (
    <div className="edit-video-name-mobile">
      <span className="header1-mobile">{videoName}</span>
      <FiEdit
        onClick={openEditNameModal}
        className="me-3 edit-header-icon-mobile"
        size={35}
      />
    </div>
  );
}

export default EditVideoNameMobile;
