import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  FiCalendar,
  FiVideo,
  FiArrowRightCircle,
  FiThumbsUp,
  FiUserCheck,
  FiBox,
} from "react-icons/fi";
import { SlGraph } from "react-icons/sl";
import { ImAttachment, ImBlocked } from "react-icons/im";
import {
  change_user_status,
  get_users_information,
  give_credit,
} from "../../api/video";
import ConfirmModal from "../Modals/confirmModal";

function UserTab() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    status: false,
    id: "",
    content: "",
  });

  useEffect(() => {
    get_users_information().then((res) => {
      if (res.status === 200) {
        setUserList(res.data.users);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [loading, navigate]);

  // functions
  const giveVideo = (id) => {
    let data = {
      id: id,
    };
    setLoading(true);
    give_credit(data).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setConfirmModal({ status: false, id: "", content: "" });
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };
  const handleUser = (id) => {
    let data = {
      id: id,
    };
    setLoading(true);
    change_user_status(data).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setConfirmModal({ status: false, id: "", content: "" });
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <>
      <table className="tab-table">
        <tr>
          <th># User Email</th>
          <th>
            <SlGraph /> User Status
          </th>
          <th>
            <FiCalendar /> Signup Date
          </th>
          <th>
            <ImAttachment /> Prompters
          </th>
          <th>
            <FiVideo /> Videos
          </th>
          <th>
            <FiBox /> Credits
          </th>
          <th>
            <FiArrowRightCircle /> Actions
          </th>
          <th></th>
        </tr>
        {userList.map((user, index) => (
          <tr key={index}>
            <td>{user.email}</td>
            <td style={{ color: user.user_status ? "#93CAFF" : "#E7573D" }}>
              {user.user_status ? (
                <div>
                  <FiThumbsUp /> Active
                </div>
              ) : (
                <div>
                  <ImBlocked /> Blocked
                </div>
              )}
            </td>
            <td>{user.signup_date}</td>
            <td>{user.prompts}</td>
            <td>{user.videos}</td>
            <td>{user.credit}</td>
            <td
              style={{ color: "#51D9DB" }}
              onClick={() =>
                setConfirmModal({
                  status: true,
                  id: user.id,
                  content: "give_video",
                })
              }
            >
              <FiUserCheck /> Give Video
            </td>
            <td
              style={{ color: user.user_status ? "#E7573D" : "#93CAFF" }}
              onClick={() =>
                setConfirmModal({
                  status: true,
                  id: user.id,
                  content: "user_status",
                })
              }
            >
              {user.user_status ? <div>Block</div> : <div>Unblock</div>}
            </td>
          </tr>
        ))}
      </table>
      <ConfirmModal
        confirmModal={confirmModal}
        closeModal={() =>
          setConfirmModal({ status: false, id: "", content: "" })
        }
        handleItem={
          confirmModal.content === "give_video" ? giveVideo : handleUser
        }
      />
    </>
  );
}

export default UserTab;
