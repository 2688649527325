import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { get_site_information } from "../../api/video";

function SiteTab() {
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    get_site_information().then((res) => {
      if (res.status === 200) {
        setSiteData(res.data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  return (
    <>
      <table className="tab-table">
        <tr>
          <th></th>
          <th>Today</th>
          <th>Month</th>
          <th>Year</th>
          <th>Total</th>
        </tr>
        <tr>
          <td>Visitors</td>
          <td>{siteData?.Visitors?.dailyLogins}</td>
          <td>{siteData?.Visitors?.monthlyLogins}</td>
          <td>{siteData?.Visitors?.yearlyLogins}</td>
          <td>{siteData?.Visitors?.totalLogins}</td>
        </tr>
        <tr>
          <td>Videos Viewed</td>
          <td>{siteData?.VideosViewed?.dailyLogins}</td>
          <td>{siteData?.VideosViewed?.monthlyLogins}</td>
          <td>{siteData?.VideosViewed?.yearlyLogins}</td>
          <td>{siteData?.VideosViewed?.totalLogins}</td>
        </tr>
        <tr>
          <td>Clicked Prompters</td>
          <td>{siteData?.ClickedPrompters?.dailyLogins}</td>
          <td>{siteData?.ClickedPrompters?.monthlyLogins}</td>
          <td>{siteData?.ClickedPrompters?.yearlyLogins}</td>
          <td>{siteData?.ClickedPrompters?.totalLogins}</td>
        </tr>
        <tr>
          <td>Finished Videos</td>
          <td>{siteData?.Finishedvideos?.dailyLogins}</td>
          <td>{siteData?.Finishedvideos?.monthlyLogins}</td>
          <td>{siteData?.Finishedvideos?.yearlyLogins}</td>
          <td>{siteData?.Finishedvideos?.totalLogins}</td>
        </tr>
        <tr>
          <td>Signups</td>
          <td>{siteData?.Signups?.dailyLogins}</td>
          <td>{siteData?.Signups?.monthlyLogins}</td>
          <td>{siteData?.Signups?.yearlyLogins}</td>
          <td>{siteData?.Signups?.totalLogins}</td>
        </tr>
      </table>
    </>
  );
}

export default SiteTab;
