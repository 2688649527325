import React from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import bgImg from "../../images/land-bg.png";
import youtubeIcon from "../../images/youtube-icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 1000,
  arrows: false,
  adaptiveHeight: true,
};

function LandingHeader() {
  const navigate = useNavigate();

  return (
    <div
      className="landing-header"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="header-content">
        <div className="header-text1">
          Connect your videos
          <div className="d-flex">
            to
            <Slider {...settings}>
              <span className="slide-text">
                <img className="slider-icon" src={youtubeIcon} alt="" />
                YouTube
              </span>
              <span className="slide-text">
                <img
                  className="slider-icon"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Connect/linkedin.png"
                  alt=""
                />
                LinkedIn
              </span>
              <span className="slide-text">
                <img
                  className="slider-icon"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/landing_page/wikipedia.png"
                  alt=""
                />
                Wikipedia
              </span>
              <span className="slide-text">
                <img
                  className="slider-icon"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Listen/spotify-icon.png"
                  alt=""
                />
                Spotify
              </span>
              <span className="slide-text">
                <img
                  className="slider-icon"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/landing_page/HBO.png"
                  alt=""
                />
                HBO
              </span>
              <span className="slide-text">
                <img
                  className="slider-icon"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Learn/imdb-icon.png"
                  alt=""
                />
                IMDB
              </span>
            </Slider>
          </div>
        </div>
        <div className="header-text2 text-white mb-5">
          We fill videos with knowledge and actions thanks to our synchronized
          cards. <br /> Made for movie lovers, design aficionados, advertising
          pros, sports analytics and fans of Casablanca..
        </div>
        <button
          className="btn color-bordered-btn text-white mb-3 me-4"
          onClick={() => navigate("/all-video")}
        >
          Watch Videos
        </button>
        <button
          className="btn sign-btn text-white mb-3"
          onClick={() => navigate("/signup")}
        >
          Beta Sign up
        </button>
        <div className="sign-text">
          *Sign up to join our BETA waiting list to become a content creator
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;
