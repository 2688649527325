import React from "react";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";
import { unsecuredCopyToClipboard } from "../../modules/asset";

function PublishSucessMobile({ videoLink, videoName, publishedId }) {
  const copy_link = `${process.env.REACT_APP_URL}view/${publishedId}`;
  const navigate = useNavigate();
  const [copyAlert, setCopyAlert] = useState(false);

  const handleCopy = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(copy_link);
      setCopyAlert(true);
    } else {
      unsecuredCopyToClipboard(copy_link);
      setCopyAlert(true);
    }
  };

  return (
    <div className="published-video-mobile">
      <ReactPlayer
        controls
        width="100%"
        height={180}
        className="d-inline-block"
        url={videoLink}
        progressInterval={100}
      ></ReactPlayer>
      <div className="published-content-mobile">
        <div className="published-video-name-mobile">{videoName}</div>
        <button className="btn fullwidth-btn mb-3" onClick={handleCopy}>
          {copyAlert ? "Copied" : "Copy Link"}
          <FiCopy className="ms-2" />
        </button>
        {/* <button
          className="btn fullwidth-bordered-btn-mobile mb-3"
          onClick={() => navigate("/dashboard")}
        >
          Back to Dashboard
        </button> */}
      </div>
    </div>
  );
}

export default PublishSucessMobile;
