import React from "react";
import { useNavigate } from "react-router";
import mobileHeaderIcon from "../../images/mobile-header-icon.png";
import { BiUser } from "react-icons/bi";
import "./index.scss";

function HeaderMobile() {
    const navigate = useNavigate();
    const token = localStorage.getItem("tokens");

    return (
        <div className="header-mobile">
            <img
                className="company-logo1-mobile"
                src={mobileHeaderIcon}
                onClick={() => navigate("/")}
                alt=""
            />
            <div>
                {/* {JSON.parse(token)?.user?.role === "admin" && (
                    <button
                        className="btn header-btn-mobile"
                        onClick={() => navigate("/admin-dashboard")}
                    >
                        Admin Dashboard
                    </button>
                )} */}
                {token ? (
                    <div>
                        <span className="profile-name-mobile">{JSON.parse(token).user.name}</span>
                        <img
                            className="profile-img-mobile"
                            src={JSON.parse(token).user.profile_img}
                            onClick={() => navigate("/dashboard")}
                            alt=""
                        />
                    </div>
                ) : (
                    <div
                        className="header-btn-mobile d-flex align-items-center justify-content-center"
                        onClick={() => navigate("/signup")}
                    >
                        <BiUser className="me-1" />
                        Signup
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeaderMobile;
