import { FiEdit } from "react-icons/fi";
import "./index.scss";

function EditVideoName({ videoName, openEditNameModal, tab }) {
  return (
    <div className="edit-video-name">
      <span className="header1">{videoName}</span>
      <FiEdit
        onClick={openEditNameModal}
        className="me-3 edit-header-icon"
        size={28}
      />
    </div>
  );
}

export default EditVideoName;
