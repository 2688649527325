import "./index.scss";

function PrivacyPolicy() {
  return (
    <div className="privacy-page">
      <div className="privacy-header">Privacy Policy</div>
      <div className="privacy-text">
        <div className="text-group">Effective 1st October 2023,</div>
        <div className="text-group">
          Metaprompter is an independent company.
          <br />
          This privacy policy will explain how our organization uses the
          personal data we collect from you when you use our website.
          <br />
          What data do we collect?
        </div>
        <div className="text-group">
          Our Company collects the following data:
          <ul>
            <li>
              Personal identification information ( name, email address, phone
              number, etc.)
            </li>
          </ul>
        </div>
        <div className="text-group">
          How do we collect your data?
          <br /> You directly provide Our Company with most of the data we
          collect. We collect and process data when you:
          <ul>
            <li>
              Register online or place an order for any of our products or
              services.
            </li>
            <li>
              Voluntarily complete a customer survey or provide feedback on our
              message boards or via email.
            </li>
            <li>Use or view our website via your browser’s cookies.</li>
          </ul>
        </div>
        <div className="text-group">
          How will we use your data?
          <br /> Our Company collects your data so we can:
          <ul>
            <li>Process your order and manage your account.</li>
            <li>
              Email you with special offers on other products and services we
              think you might like.
            </li>
            <li>Improve the experience and present relevant information</li>
          </ul>
          If you agree, Our Company will share your data with our partner
          companies so that they may offer you their products and services.
          <br /> When Our Company processes your order, it may send your data
          to, and also use the resulting information from, credit reference
          agencies to prevent fraudulent purchases. How do we store your data?
          <br /> Our Company securely stores your data at Marketing
          <br /> You have the right at any time to stop Our Company from
          contacting you for marketing purposes or giving your data to other
          members of the Our Company Group. <br />
          Please click here if you no longer wish to be contacted for marketing
          purposes.
        </div>
        <div className="text-group">
          Cookies <br />
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology. <br />
          For further information, visit allaboutcookies.org. <br />
          How do we use cookies? <br />
          Our Company uses cookies in a range of ways to improve your experience
          on our website, including:
          <ul>
            <li>Keeping you signed in</li>
            <li>Understanding how you use our website</li>
            <li>[Add any uses your company has for cookies]</li>
          </ul>
          What types of cookies do we use?
          <br />
          There are a number of different types of cookies, however, our website
          uses:
          <ul>
            <li>
              Functionality – Our Company uses these cookies so that we
              recognize you on our website and remember your previously selected
              preferences. These could include what language you prefer and
              location you are in. A mix of first-party and third-party cookies
              are used.
            </li>{" "}
            <li>
              Advertising – Our Company uses these cookies to collect
              information about your visit to our website, the content you
              viewed, the links you followed and information about your browser,
              device, and your IP address. Our Company sometimes shares some
              limited aspects of this data with third parties for advertising
              purposes. We may also share online data collected through cookies
              with our advertising partners. This means that when you visit
              another website, you may be shown advertising based on your
              browsing patterns on our website.
            </li>
            <li>[Add any other types of cookies your company uses]</li>
          </ul>
          How to manage cookies <br />
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser.
          <br />
          However, some of our website features may not function in a few cases.
          Privacy policies of other websites <br />
          The Our Company website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
        </div>
        <div className="text-group">
          Changes to our privacy policy <br />
          Our Company keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on
          9 January 2019. <br />
          How to contact us
          <br />
          If you have any questions about Our Company’s privacy policy, or the
          data we hold on you, or if you would like to exercise one of your data
          protection rights, please do not hesitate to contact us. <br />
          Email us at: ivs@metaprompter.com <br />
          How to get the appropriate authority. <br />
          Should you wish to report a complaint or if you feel that Our Company
          has not addressed your concern adequately, you may contact the
          Information Commissioner’s Office.
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
