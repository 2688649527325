import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FiSearch } from "react-icons/fi";
import VideoList from "../videoList/videoList";
import { get_user_videos } from "../../api/video";

function MyVideo() {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(12);
  const [listLength, setListLength] = useState(0);

  useEffect(() => {
    get_user_videos(firstIndex, lastIndex).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
        setListLength(res.data.total_cnt);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate, firstIndex, lastIndex]);

  const fetchMoreData = () => {
    setFirstIndex(lastIndex);
    setLastIndex(2 * lastIndex - firstIndex);
    get_user_videos(lastIndex, 2 * lastIndex - firstIndex).then((res) => {
      if (res.status === 200) {
        let tempList = [...videoList];
        tempList.push(...res.data.video_data);
        setVideoList(tempList);
        if (tempList.length < listLength) setHasMore(true);
        else setHasMore(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className="my-video-layout">
      <div className="video-header">
        <div className="header-text">My videos</div>
        <FiSearch className="header-search" />
      </div>
      <div className="video-list">
        <VideoList
          column={6}
          videoList={videoList}
          setVideoList={setVideoList}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
          removal={true}
        />
      </div>
    </div>
  );
}

export default MyVideo;
