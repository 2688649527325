import { AiFillCloseCircle } from "react-icons/ai";
import { makeTime } from "../../modules/asset";

function PrompterList({ list, removeRecord, handleRecord }) {
  return (
    <div className="prompter-layout">
      <div className="list-layout">
        {list &&
          list.map((each, index) => (
            <div className="prompter-card" key={index}>
              <AiFillCloseCircle
                className="prompter-close-icon"
                onClick={() => removeRecord(index)}
              />
              <div
                className="prompter-card-content"
                onClick={() => handleRecord(each)}
              >
                <div className="prompter-card-time">
                  {makeTime(each.time_slot)}
                </div>
                <div
                  className="prompter-card-name"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={each.name}
                >
                  {each.name}
                </div>
                <img className="prompter-card-icon" src={each.icon} alt="" />
              </div>
              <div className="prompter-card-footer">{each.service}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default PrompterList;
