import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import cameraIcon from "../../images/camera-icon.png";
import profileIcon from "../../images/profile-icon.png";
import cloudIcon from "../../images/cloud-icon.png";
import arrowRight from "../../images/arrow-right.png";
import arrowBottom from "../../images/arrow-bottom.png";
import logoutIcon from "../../images/logout-icon.png";
import "./index.scss";

function AccountManageMobile() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.dashboard.userData);
  const [tab, setTab] = useState({
    serviceList: false,
    service: false,
    performance: false,
    credits: false,
    logout: false,
  });

  // functions
  const handleTab = (list) => {
    let tempTab = { ...tab };
    tempTab[list] = !tempTab[list];
    setTab(tempTab);
  };
  const handleLogout = () => {
    localStorage.removeItem("tokens");
    navigate("/login");
    window.location.reload();
  };

  return (
    <div className="account-manage-layout-mobile">
      <div className="dashboard-sub-header-mobile">Account Management</div>
      <div className="dashboard-option-card-mobile">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content-mobile">
            <div className="d-flex align-items-center">
              <img
                className="card-content-icon-mobile"
                src={cameraIcon}
                alt=""
              />{" "}
              <span className="card-content-header-mobile">
                Create a New Video
              </span>
            </div>
            <div className="card-desc-mobile">
              You will be redirected to the “Upload Video” page.
            </div>
          </div>
          <div
            className="expand-card-mobile"
            onClick={() => navigate("/edit/0")}
          >
            <img src={arrowRight} alt="" />
          </div>
        </div>
      </div>
      <div className="dashboard-option-card-mobile">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content-mobile">
            <div className="d-flex align-items-center">
              <img
                className="card-content-icon-mobile"
                src={profileIcon}
                alt=""
              />{" "}
              <span className="card-content-header-mobile">
                Check Performance
              </span>
            </div>
            <div className="card-desc-mobile">
              Review number of videos, prompters and services
            </div>
          </div>
          <div
            className="expand-card-mobile"
            onClick={() => handleTab("performance")}
          >
            <img src={tab.performance ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.performance && (
          <div className="performance-content-mobile">
            <div className="dashboard-card-list-mobile">
              <span>Uploaded Videos</span>
              <span>{userData?.checkperformance?.FinishedVideos}</span>
            </div>
            <div className="dashboard-card-list-mobile">
              <span>Prompters Seen</span>
              <span>
                {userData?.checkperformance?.ClickedPrompters?.totalLogins}
              </span>
            </div>
            <div className="dashboard-card-list-mobile">
              <span>Views</span>
              <span>
                {userData?.checkperformance?.VideosViewed?.totalLogins}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-option-card-mobile">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content-mobile">
            <div className="d-flex align-items-center">
              <img
                className="card-content-icon-mobile"
                src={cloudIcon}
                alt=""
              />{" "}
              <span className="card-content-header-mobile">Credits</span>
            </div>
            <div className="card-desc-mobile">
              Buy Credits and review credit information
            </div>
          </div>
          <div
            className="expand-card-mobile"
            onClick={() => handleTab("credits")}
          >
            <img src={tab.credits ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.credits && (
          <div className="credits-content-mobile">
            <div className="dashboard-card-list-mobile">
              <span>Available credits</span>
              <span>{userData?.user?.credit}</span>
            </div>
            <div
              className="fullwidth-btn mt-4"
              onClick={() => navigate("/buy-credit")}
            >
              Purchase More Credits
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-sub-header-mobile">Profile</div>
      <div className="dashboard-option-card-mobile">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content-mobile">
            <div className="d-flex align-items-center">
              <img src={logoutIcon} alt="" />{" "}
              <span className="card-content-header-mobile">Log Out</span>
            </div>
            <div className="card-desc-mobile">
              Log out of your current profile
            </div>
          </div>
          <div
            className="expand-card-mobile"
            onClick={() => handleTab("logout")}
          >
            <img src={tab.logout ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.logout && (
          <div className="fullwidth-btn mt-4" onClick={handleLogout}>
            Log Out
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountManageMobile;
