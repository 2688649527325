import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import StripeCheckout from "react-stripe-checkout";
import selectCreditIcon from "../../images/select-credit-icon.png";
import creditRemainingIcon from "../../images/credit-remaining-icon.png";
import { pay_stripe, get_user } from "../../api/video";
import "./index.scss";

function BuyCreditMobile() {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    get_user().then((res) => {
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  const handleCreditAmount = (operator) => {
    if (operator === "-" && creditAmount > 0) setCreditAmount(creditAmount - 1);
    if (operator === "+") setCreditAmount(creditAmount + 1);
  };

  const onToken = (token) => {
    let data = {
      token: token.id,
      email: token.email,
      amount: creditAmount,
    };

    pay_stripe(data).then((res) => {
      if (res.status === 200) {
        setTab(1);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
    console.log(onToken);
  };

  return (
    <div>
      <div className="buy-credit-layout-mobile">
        <div className="buy-credit-content-mobile">
          <div className="buy-header-mobile">Buy More Credits</div>
          <div className="select-credits-text-mobile">
            <img src={selectCreditIcon} alt="" />
            <span>Select credits</span>
          </div>
          <div className="select-credits-input-mobile">
            <BiMinusCircle
              className="select-credits-icon-mobile"
              onClick={() => handleCreditAmount("-")}
            />
            <span className="credit-count-mobile">
              <input
                type="number"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
              />
            </span>
            <BiPlusCircle
              className="select-credits-icon-mobile"
              onClick={() => handleCreditAmount("+")}
            />
          </div>
          <div className="credit-remaining-text-mobile">
            <img src={creditRemainingIcon} alt="" />
            <span>Credits remaining: {userData?.user?.credit}</span>
          </div>
          <div className="credit-description-mobile">
            <div>1 Credit = $1</div>{" "}
            <div>
              With 5 Credits, you will be able to create and publish a video
              with as many prompters as you want.
            </div>
            <div>Your first video is free!</div>
          </div>
          {tab === 0 ? (
            <>
              <StripeCheckout
                token={onToken}
                stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
              >
                <button
                  className="btn fullwidth-btn mb-3"
                  disabled={!creditAmount}
                >
                  Continue
                </button>
              </StripeCheckout>
              <button
                className="btn color-bordered-btn w-100"
                onClick={() => navigate("/dashboard")}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button className="btn fullwidth-btn w-100 success-btn-mobile mb-3">
                Payment approved successfully.
              </button>
              <div
                className="btn color-bordered-btn w-100"
                onClick={() => navigate("/dashboard")}
              >
                Return to my Dashboard
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BuyCreditMobile;
