import { Modal, ModalBody } from "reactstrap";

function RemoveConfirmModal({ removeModal, closeModal, removeItem }) {
  return (
    <Modal
      className="remove-confirm-modal"
      isOpen={removeModal.status}
      toggle={closeModal}
    >
      <ModalBody className="remove-confirm-modal-body">
        <div className="remove-text">
          Are you sure to delete this {removeModal.content}?
        </div>
        <div className="remove-btn-group">
          <button
            className="btn btn-primary handle-btn me-3"
            onClick={() => removeItem(removeModal.id)}
          >
            Yes
          </button>
          <button className="btn btn-secondary handle-btn" onClick={closeModal}>
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default RemoveConfirmModal;
