function StepBar({ tab, tabLabels }) {
  return (
    <div className="step-bar">
      {tabLabels.map((label, index) => (
        <div className="d-flex align-items-center" key={index}>
          <div className="step-block">
            <div className={`step-index ${tab >= index && "active-index"}`}>{index + 1}</div>
            <div className={`step-label ${tab === index && "active-label"}`}>{label}</div>
          </div>
          {index !== tabLabels.length - 1 && <hr className={`step-divider ${tab >= index + 1 && "active-devider"}`} />}
        </div>
      ))}
    </div>
  );
}

export default StepBar;
