import axios from "axios";

function get_videos(first, last) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}all_videos/${first}/${last}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_user_videos(first, last) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}user_videos/${first}/${last}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function publish_by_file(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}upload_video`,
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function publish_by_url(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}upload1`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function remove_video(data) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}video_delete/${data.video_id}`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_video_by_id(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_video_by_id/${id}`,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_video_plus_by_id(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_video_plus_by_id/${id}`,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function check_record(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}check_record`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function delete_video_record(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}delete_video_record/${id}`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function plus_record_count(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}plus_record_count/${id}`,
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
}

function publish_video(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}update_video`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_all_records() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_all_records`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_action_list() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_actions`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function add_virtual_service(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}add_virtual_service`,
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function pay_stripe(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}pay`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_users_information() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_users_information`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_requested_services() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_requested_services`,
    headers: {
      "content-type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function change_user_status(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}change_user_status`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function give_credit(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}give_credit`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function handle_request(status, id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}selection_service/${status}/${id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_site_information() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_site_information`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_user() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_user`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_landing_videos() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_landing_videos`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function get_services_list() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}get_approved_services`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function update_action(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}update_action`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function update_icon(data) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API}update_icon`,
    headers: {
      "content-type": "multipart/form-data",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
    data,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function delete_service(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}delete_service/${id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": JSON.parse(localStorage.getItem("tokens")).token,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

function related_videos(id) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API}related_videos/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error.response);
      return error.response;
    });
}

export {
  get_videos,
  get_user_videos,
  publish_by_file,
  publish_by_url,
  remove_video,
  get_video_by_id,
  get_video_plus_by_id,
  check_record,
  delete_video_record,
  plus_record_count,
  get_all_records,
  publish_video,
  get_action_list,
  add_virtual_service,
  pay_stripe,
  get_users_information,
  get_requested_services,
  change_user_status,
  give_credit,
  handle_request,
  get_site_information,
  get_user,
  get_landing_videos,
  get_services_list,
  update_action,
  update_icon,
  delete_service,
  related_videos,
};
