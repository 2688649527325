import { useNavigate } from "react-router";
import { FiHome, FiPlusSquare, FiSearch } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import "./index.scss";

function FooterControlMobile() {
    const navigate = useNavigate();
    const token = localStorage.getItem("tokens");

    return (<div className="footer-control-mobile-layout">
        <div className="text-center" onClick={() => navigate("/")}>
            <FiHome />
            <div className="control-text-mobile">Home</div>
        </div>
        <div className="text-center" onClick={() => navigate("/edit/0")}>
            <FiPlusSquare />
            <div className="control-text-mobile">New Video</div>
        </div>
        <div className="text-center">
            <FiSearch />
            <div className="control-text-mobile">Search</div>
        </div>
        {token ? <div className="text-center">
            <img
                className="control-img-mobile"
                src={JSON.parse(token).user.profile_img}
                onClick={() => navigate("/dashboard")}
                alt=""
            />
            <div className="control-text-mobile">{JSON.parse(token).user.name}</div>
        </div> : <div className="text-center" onClick={() => navigate('/login')}>
            <BiUser />
            <div className="control-text-mobile">Sign In</div>
        </div>}

    </div>)
}

export default FooterControlMobile;