import { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./index.scss";

function EditNameModal({ open, onClose, videoName, setVideoName }) {
  const [name, setName] = useState(videoName);

  useEffect(() => {
    setName(videoName);
  }, [videoName]);
  // functions
  const handleName = () => {
    setVideoName(name);
    onClose();
  };
  return (
    <Modal className="edit-name-modal" isOpen={open} toggle={onClose}>
      <ModalBody className="edit-name-body">
        <label htmlFor="edit-video-name-input" className="edit-name-label">
          Edit Video Name
        </label>
        <input
          id="edit-video-name-input"
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Input Video Name"
        />
        <div className="text-center mt-3">
          <button className="btn edit-name-btn" onClick={handleName}>
            Update
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EditNameModal;
