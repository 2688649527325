import { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import bgImg from "../../images/land-bg.png";
import "./index.scss";

function VideoUploadMobile({ uploadFile, uploadUrl }) {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");
  const [disable, setDisable] = useState(true);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (file && link) setDisable(true);
    else if (file || link) setDisable(false);
    else setDisable(true);
  }, [file, link]);

  // functions
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    if (acceptedFiles[0]) {
      setFile(acceptedFiles[0]);
      uploadFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/mp4",
  });

  const handleUpload = () => {
    if (file && link) alert("Please input one of them.");
    else if (file) uploadFile(file);
    else if (link) uploadUrl(link);
    else alert("Please input file or link.");
  };

  return (
    <div
      className="video-upload-layout-mobile"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <button
        className="btn fullwidth-bordered-btn-mobile mt-2"
        disabled={disable}
        onClick={handleUpload}
      >
        Insert YouTube Link
      </button> */}
      <div
        className="fullwidth-btn-mobile"
        onClick={() => setShowInput(!showInput)}
      >
        Insert YouTube Link
        {showInput ? <RiArrowUpSLine className="ms-2" /> : <RiArrowDownSLine className="ms-2" />}
      </div>
      {showInput && <div className="d-flex justify-content-between mt-2">
        <input
          type="text"
          className="url-input-mobile form-control"
          onChange={(e) => setLink(e.target.value)}
          placeholder="Insert youtube link"
        />
        <button className="btn btn-mobile" onClick={handleUpload} disabled={disable}>Add Video</button>
      </div>}
      <div className="or-text-mobile">or</div>
      <div className="video-upload-form-mobile">
        <div className="w-100" {...getRootProps()}>
          <FiUploadCloud className="video-upload-icon-mobile" />
          {/* <div className="choose-text-mobile mt-5">
            Choose a video file to upload
          </div>
          <div>Max file size: 50MB</div>
          <div>Max length : 3 minutes or 180 seconds</div>
          <div>pported file types: MP4.MOV.WMV.FLV.AVI</div> */}
          <input type="file" name="file" {...getInputProps()} />
          <div className="fullwidth-bordered-btn-mobile mt-4">Select Files</div>
        </div>
      </div>
      <div className="submit-text-mobile">
        By submitting your videos to MetaPrompter, you acknowledge that you
        agree to
        <br />
        MetaPrompter’s{" "}
        <span className="focus-text-mobile">Terms of Service</span> and{" "}
        <span className="focus-text-mobile">privacy rights.</span>
      </div>
      <br />
      <div className="submit-text-mobile pb-5">
        Please be sure not to violate others' copyright or privacy rights.{" "}
        <span className="focus-text-mobile">Learn more</span>
      </div>
    </div>
  );
}

export default VideoUploadMobile;
