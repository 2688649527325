import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { get_landing_videos } from "../../api/video";
import playIcon from "../../images/play_icon.png";

function LandingContent() {
  const navigate = useNavigate();
  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    get_landing_videos().then((res) => {
      if (res.status === 200) {
        setVideoData(res.data.video_data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  return (
    <div className="landing-content">
      <div className="row">
        <div className="col-3">
          <div className="add-video">
            <div className="add-video-header">Contribute</div>
            <div className="add-video-text mb-3">
              Send us a video you would like us to feed with prompters
            </div>
            <button
              className="btn start-btn mb-3 w-100"
              onClick={() => navigate("/signup")}
            >
              Send video Link
            </button>
          </div>
        </div>
        <div className="p-4 col-3">
          <div className="related-video">
            <ReactPlayer
              style={{ button: { display: "none" } }}
              className="react-player"
              url={videoData[0]?.url}
              height="100%"
              width="100%"
              playIcon={<div></div>}
              light={true}
            ></ReactPlayer>
            <img
              className="play-icon"
              src={playIcon}
              onClick={() => navigate(`/view/${videoData[0].id}`)}
              alt=""
            />
          </div>
        </div>
        <div className="col-3">
          <div className="add-video">
            <div className="add-video-header">Have ideas?</div>
            <div className="add-video-text mb-3">
              Marketers, film enthusiasts and other people with ideas on how to
              improve
            </div>
            <a
              className="btn start-btn mb-3 w-100"
              href="mailto:ivs@metaprompter.com"
            >
              Send your thoughts
            </a>
          </div>
        </div>
        <div className="p-4 col-3">
          <div className="related-video">
            <ReactPlayer
              style={{ button: { display: "none" } }}
              className="react-player"
              url={videoData[1]?.url}
              height="100%"
              width="100%"
              playIcon={<div></div>}
              light={true}
            ></ReactPlayer>
            <img
              className="play-icon"
              src={playIcon}
              onClick={() => navigate(`/view/${videoData[0].id}`)}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="row">
        {videoData.map(
          (each, index) =>
            index > 1 && (
              <div className="p-4 col-3" key={index}>
                <div className="related-video">
                  <ReactPlayer
                    style={{ button: { display: "none" } }}
                    className="react-player"
                    url={each.url}
                    height="100%"
                    width="100%"
                    playIcon={<div></div>}
                    light={true}
                  ></ReactPlayer>
                  <img
                    className="play-icon"
                    src={playIcon}
                    onClick={() => navigate(`/view/${each.id}`)}
                    alt=""
                  />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default LandingContent;
