import { isMobile } from "react-device-detect";
import Header from "../components/header";
import LandingFooter from "../components/landing/landingFooter";
import Footer from "../components/footer";
import HeaderMobile from "../components/headerMobile";
import FooterControlMobile from "../components/footerControlMobile";

function PublicRoute({ children }) {
  return (
    <>
      {isMobile ? (<HeaderMobile />) : (<Header />)}
      {children}
      <LandingFooter />
      {isMobile ? <FooterControlMobile /> : <Footer />}
    </>
  );
}

export default PublicRoute;
