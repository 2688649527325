import { makeTime, check_video_time_list } from "../../modules/asset";
import "./index.scss";

function PrompterBoardMobile({
  handleRecord,
  video,
  start,
  stay,
  time,
  hiddenTime,
}) {
  return (
    <div className="prompter-board-mobile">
      {video?.records?.slice().reverse().map((each, index) => {
        if (
          start &&
          !stay &&
          check_video_time_list(time, each.time_slot, hiddenTime)
        ) {
          return (
            <div
              className={
                time < parseInt(each.time_slot)
                  ? "event-ticket-mobile up-comming-mobile"
                  : "event-ticket-mobile"
              }
              key={index}
              onClick={() => handleRecord(each)}
            >
              <div className="ticket-body-mobile">
                <div className="ticket-time-mobile">{makeTime(each.time_slot)}</div>
                <div className="publish-name-mobile">{each.name}</div>
                <img className="ticket-abb-mobile" src={each.icon} alt="" />
              </div>
              <div className="ticket-footer-mobile">
                <span>{each.service}</span>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default PrompterBoardMobile;
