import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { get_videos, remove_video } from "../../api/video";
import RemoveConfirmModal from "../Modals/removeConfirmModal";

function VideoTab() {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [removeModal, setRemoveModal] = useState({
    status: false,
    content: "video",
    id: "",
  });

  useEffect(() => {
    get_videos(-1, -1).then((res) => {
      if (res.status === 200) {
        setVideoList(res.data.video_data);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [navigate]);

  // functions
  const handleRemove = (id) => {
    let data = {
      video_id: id,
    };
    remove_video(data).then((res) => {
      if (res.status === 200) {
        // setVideoList(res.data.video_data);
        let video_arr = videoList.filter((video) => video.id !== id);
        setVideoList([...video_arr]);
        setRemoveModal({ status: false, content: "video", id: "" });
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <>
      <table className="tab-table">
        <tr>
          <th>Video Name</th>
          <th>Creation Date</th>
          <th>Prompters</th>
          <th>Views</th>
          <th>Actions</th>
        </tr>
        {videoList.map((video, index) => (
          <tr key={index}>
            <td>{video.name}</td>
            <td>{video.created_at}</td>
            <td>{video.records_count}</td>
            <td>{video.totalLogins}</td>
            <td
              className="text-danger"
              onClick={() =>
                setRemoveModal({ status: true, content: "video", id: video.id })
              }
            >
              Delete
            </td>
          </tr>
        ))}
      </table>
      <RemoveConfirmModal
        removeItem={handleRemove}
        removeModal={removeModal}
        closeModal={() =>
          setRemoveModal({ status: false, content: "video", id: "" })
        }
      />
    </>
  );
}

export default VideoTab;
