import React from "react";
import ReactPlayer from "react-player";
import playIcon from "../../images/play_icon.png";
import { useNavigate } from "react-router";
import { remove_video } from "../../api/video";
import "./index.scss";

function VideoListMobile({ videoList, setVideoList, removal }) {
  const navigate = useNavigate();

  const handleRemove = (id) => {
    let data = {
      video_id: id,
    };
    remove_video(data).then((res) => {
      if (res.status === 200) {
        // setVideoList(res.data.video_data);
        let video_arr = videoList.filter((video) => video.id !== id);
        setVideoList([...video_arr]);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  return (
    <div>
      {videoList.map((each, index) => (
        <div key={index}>
          <div className="video-card-mobile">
            <ReactPlayer
              style={{ button: { display: "none" } }}
              className="react-player"
              url={each.url}
              height="100%"
              width="100%"
              playIcon={<img className="play-icon" src={playIcon} alt="" />}
              light={true}
              onClick={() => navigate(`/view/${each.id}`)}
            ></ReactPlayer>
            {removal && <div className="video-delete-mobile" onClick={() => handleRemove(each.id)}>Delete</div>}
          </div>
          <div className="video-card-header-mobile">{each.name}</div>
        </div>
      ))}
    </div>
  );
}

export default VideoListMobile;
