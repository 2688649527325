import React from "react";
import DashboardContent from "./dashboardContent";
import MyVideo from "./myVideos";
import "./index.scss";

function DashboardPart() {
  return (
    <div className="dashboard-layout">
      <div className="d-flex justify-content-between align-items-center">
        <div className="dashboard-title">Dashboard</div>
        <span className="dashboard-help">Help</span>
      </div>
      <DashboardContent />
      <MyVideo />
    </div>
  );
}

export default DashboardPart;
