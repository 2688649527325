import { useCallback, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { MdUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import "./index.scss";

function MediaUploadModal({ open, onClose, uploadFile, uploadUrl }) {
  const [videoLink, setVideoLink] = useState("");

  // functions
  const onDrop = useCallback((acceptedFiles) => {
    uploadFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/mp4",
  });

  return (
    <Modal className="media-upload-modal" isOpen={open} toggle={onClose}>
      <ModalBody className="text-center media-upload-content">
        <div {...getRootProps()}>
          <div>
            <MdUpload color="#666666" size={110} />
          </div>
          <div className="dnd-text">Drag and drop video file to upload</div>
          <div className="private-text">
            Your videos will be private until you publish them
          </div>
          <input type="file" name="file" {...getInputProps()} />
          <button className="btn select-file-btn">Select Files</button>
        </div>
        <div className="or-text">or</div>
        <div className="d-flex justify-content-center mt-5">
          <input
            type="text"
            className="form-control insert-link-input"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            placeholder="insert youtube link"
          />
          <button
            className="btn add-video-btn"
            onClick={() => uploadUrl(videoLink)}
          >
            add video
          </button>
        </div>
        <div className="privacy-text">
          By submitting your videos to MetaPrompter, you acknowledge that you
          agree to MetaPrompter’s{" "}
          <span className="terms-text">Terms of Service</span> and{" "}
          <span className="terms-text">privacy rights</span>.
        </div>
        <div className="privacy-text mt-3">
          Please be sure not to violate others' copyright or privacy rights.
          <span className="terms-text">Learn more</span>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default MediaUploadModal;
