import { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

function VideoUpload({ uploadFile, uploadUrl }) {
    const [file, setFile] = useState(null);
    const [link, setLink] = useState("");
    const [disable, setDisable] = useState(true);


    useEffect(() => {
        if (file && link) setDisable(true);
        else if (file || link) setDisable(false);
        else setDisable(true);
    }, [file, link])

    // functions
    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
        uploadFile(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "video/mp4",
    });

    return (
        <div className="video-upload-layout">
            <div className="d-flex justify-content-center">
                <input type="text" className="form-control link-input m-2" onChange={(e) => setLink(e.target.value)} placeholder="Insert youtube link" />
                <button className="btn add-video-btn m-2" disabled={disable} onClick={() => uploadUrl(link)}>
                    Add Video
                </button>
            </div>
            <div className="divider-text">
                <div className="or-text">or</div>
            </div>
            <div className="video">
                <div className="video-upload-form"  {...getRootProps()}>
                    <FiUploadCloud className="file-upload-icon" />
                    <div className="drag-text">Drag and drop  video file to upload</div>
                    <div className="mt-4 mb-4">Your videos will be private until you  publish them</div>
                    <div className="video-limit-text">Select a file before continuing</div>
                    <div>Max file size: <span className="fw-bold">50MB</span></div>
                    <div>Max file size: <span className="fw-bold">3 minutes or 180 seconds</span></div>
                    <div>Supported file types: <span className="fw-bold">MP4 · MOV · WMV · FLV · AVI</span></div>
                    <input type="file" name="file" {...getInputProps()} />
                    <div className="fullwidth-btn select-file-btn mt-5">Select Files</div>
                </div>
            </div>
            <div className="submit-text">
                By submitting your videos to MetaPrompter, you acknowledge that you agree to MetaPrompter’s <br /><span className="focus-text">Terms of Service</span> and <span className="focus-text">privacy rights.</span></div>
            <br />
            <div className="submit-text pb-5">
                Please be sure not to violate others' copyright or privacy rights. <span className="focus-text">Learn more</span>
            </div>
        </div>
    )
}

export default VideoUpload;