import React from "react";
import { useNavigate } from "react-router";
import logo from "../../images/logo.png";
import smLogo from "../../images/sm-logo.png";
import "./index.scss";

function Header() {
  const navigate = useNavigate();
  const token = localStorage.getItem("tokens");

  return (
    <div className="header">
      <img
        className="company-logo1"
        src={smLogo}
        onClick={() => navigate("/")}
        alt=""
      />
      <img className="company-logo2" src={logo} alt="" />
      <div>
        <button className="btn header-btn" onClick={() => navigate("/")}>
          Home
        </button>
        <button
          className="btn header-btn"
          onClick={() => navigate("/all-video")}
        >
          All Videos
        </button>
        {/* {token && (
          <button
            className="btn header-btn"
            onClick={() => navigate("/my-service")}
          >
            My Services
          </button>
        )} */}
        {JSON.parse(token)?.user?.role === "admin" && (
          <button
            className="btn header-btn"
            onClick={() => navigate("/admin-dashboard")}
          >
            Admin Dashboard
          </button>
        )}
        {token ? (
          <img
            className="profile-img"
            src={JSON.parse(token).user.profile_img}
            onClick={() => navigate("/dashboard")}
            alt=""
          />
        ) : (
          <button
            className="btn header-btn"
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;
