import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { FaBars, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import MediaUploadModal from "../../components/Modals/mediaUploadModal";
import PublishSucessMobile from "../../components/EditVideoMobile/publishSuccessMobile";
import loadingGif from "../../images/loading.gif";
import AlertModal from "../../components/Modals/alertModal";
import VideoUploadMobile from "../../components/EditVideoMobile/videoUploadMobile";
import EditVideoNameMobile from "../../components/EditVideoMobile/editVideoNameMobile";
import PrompterListMobile from "../../components/EditVideoMobile/prompterListMobile";
import PrompterFormMobile from "../../components/EditVideoMobile/prompterFormMobile";
import EditNameModal from "../../components/Modals/editNameModal";
import VideoProcessBtnGroupMobile from "../../components/EditVideoMobile/videoProcessBtnGroupMobile";
import { add_sort_records } from "../../modules/asset";
import {
  get_video_by_id,
  check_record,
  publish_by_file,
} from "../../api/video";
import "./index.scss";

function EditMobile() {
  const params = useParams();
  const navigate = useNavigate();
  const player = useRef(null);
  const [tab, setTab] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [time, setTime] = useState(0);
  const [file, setFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [videoName, setVideoName] = useState("Video Title");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [editNameModal, setEditNameModal] = useState(false);
  const [alert, setAlert] = useState({ content: "", isErr: false });
  const [publishedId, setPublishedId] = useState("");
  const [showPrompterList, setShowPrompterList] = useState(true);

  const maxTime = parseInt(process.env.REACT_APP_VIDEO_MAX_TIME);

  useEffect(() => {
    if (params.videoId === "0") setTab(0);
    else {
      get_video_by_id(params.videoId).then((res) => {
        if (res.status === 200) {
          setTab(1);
          setVideoLink(res.data.link);
          setRecords(res.data.records);
          setVideoName(res.data.name);
          setLoading(false);
        } else {
          if (res.data.message === "Token is invalid or has expired!") {
            localStorage.removeItem("tokens");
            navigate("/login");
            window.location.reload();
          }
        }
      });
    }
  }, []);

  // Function
  const mediaToggle = () => {
    setUploadModal(!uploadModal);
  };
  const editNameToggle = () => {
    setEditNameModal(!editNameModal);
  };
  const onProgress = () => {
    let current_time = parseInt(player.current.getCurrentTime());
    if (current_time > maxTime) {
      player.current.seekTo(maxTime);
      setTime(maxTime);
    } else setTime(current_time);
  };
  const handleTime = (time) => {
    setTime(time);
    player.current.seekTo(time);
  };
  const handleRecord = (record) => {
    setName(record.name);
    setLink(record.link);
    setTime(record.time_slot);
    player.current.seekTo(record.time_slot);
  };
  const uploadFile = (file) => {
    setFile(file);
    setVideoLink(URL.createObjectURL(file));
    setVideoName(file.name.substring(0, file.name.lastIndexOf(".")));
    setTab(1);
    setUploadModal(false);
  };
  const uploadUrl = (url) => {
    setVideoLink(url);
    setTab(1);
    setUploadModal(false);
  };

  // API
  const addRecord = () => {
    let reqBody = {
      url: link,
    };
    check_record(reqBody).then((res) => {
      if (res.status === 200) {
        let new_record = {
          time_slot: time,
          name: name,
          ...res.data,
        };
        let new_records = add_sort_records(records, new_record);
        setRecords(new_records);
        setName("");
        setLink("");
        setShowPrompterList(true);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        } else {
          setAlert({ content: res.data, isErr: true });
        }
      }
    });
  };
  const removeRecord = (index) => {
    let temp_records = [...records];
    temp_records.splice(index, 1);
    setRecords(temp_records);
  };
  const handlePublish = () => {
    let req_body = new FormData();
    if (file) {
      req_body.append("file", file);
      let req_data = {
        id: params.videoId,
        name: videoName,
        records: records,
      };
      req_body.append("data", JSON.stringify(req_data));
    } else {
      let req_data = {
        id: params.videoId,
        url: videoLink,
        name: videoName,
        records: records,
      };
      req_body.append("data", JSON.stringify(req_data));
    }
    setLoading(true);
    publish_by_file(req_body).then((res) => {
      if (res.status === 200) {
        setPublishedId(res.data.id);
        setVideoLink(res.data.url);
        setLoading(false);
        setTab(2);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        } else {
          setAlert({ content: res.data, isErr: true });
          setLoading(false);
        }
      }
    });
  };
  const removeVideo = () => {
    setTab(0);
    setVideoName("Video Title");
    setRecords([]);
    setName("");
    setLink("");
    navigate("/deleted_video");
  };

  return tab === 0 ? (
    <VideoUploadMobile uploadFile={uploadFile} uploadUrl={uploadUrl} />
  ) : (
    <div className="edit-page-mobile">
      <div className="edit-header-mobile">
        {tab === 2 && "Congratulations!"}
      </div>
      {tab === 2 && (
        <div className="publish-text-mobile">Your video is now published.</div>
      )}

      {tab === 1 &&
        (loading ? (
          <div className="loading-screen">
            <img className="page-loading-gif" src={loadingGif} alt="" />
          </div>
        ) : (
          <div>
            <EditVideoNameMobile
              videoName={videoName}
              openEditNameModal={editNameToggle}
              tab={tab}
            />
            <div className="edit-video-layout-mobile">
              <ReactPlayer
                ref={player}
                controls
                width={"100%"}
                height={"180px"}
                className="d-inline-block"
                url={videoLink}
                progressInterval={100}
                onProgress={onProgress}
                playing={playing}
                onPlay={() => setPlaying(true)}
                config={{
                  youtube: {
                    playerVars: {
                      start: 0,
                      end: maxTime,
                    },
                  },
                }}
              ></ReactPlayer>
            </div>
            <div className="edit-content-layout-mobile">
              <VideoProcessBtnGroupMobile removeVideo={removeVideo} handlePublish={handlePublish} />
              <div
                className="prompter-list-header"
                onClick={() => setShowPrompterList(!showPrompterList)}
                style={
                  showPrompterList ? {
                    borderTop: "1px solid #0E1718",
                  } : {
                    borderTop: "1px solid #576062",
                    position: "fixed",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#03111F"
                  }
                }
              >
                <FaBars />
                <span>List of Prompters</span>
                {showPrompterList ? <FaChevronDown /> : <FaChevronUp />}
              </div>
              {showPrompterList ? <PrompterListMobile
                list={records}
                removeRecord={removeRecord}
                handleRecord={handleRecord}
                setShowPrompterList={setShowPrompterList}
              /> :
                <PrompterFormMobile
                  name={name}
                  link={link}
                  setName={setName}
                  setLink={setLink}
                  time={time}
                  addPrompter={addRecord}
                  handleTime={handleTime}
                />
              }
            </div>
          </div>
        ))}
      {tab === 2 && (
        <PublishSucessMobile
          videoLink={videoLink}
          videoName={videoName}
          publishedId={publishedId}
        />
      )}
      <MediaUploadModal
        open={uploadModal}
        onClose={mediaToggle}
        uploadFile={uploadFile}
        uploadUrl={uploadUrl}
      />
      <EditNameModal
        open={editNameModal}
        onClose={editNameToggle}
        videoName={videoName}
        setVideoName={setVideoName}
      />
      <AlertModal
        alert={alert}
        closeAlert={() => setAlert({ content: "", isErr: false })}
      />
    </div>
  );
}

export default EditMobile;
