import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { useState } from "react";
import { FiShare2, FiCopy } from "react-icons/fi";
import { unsecuredCopyToClipboard } from "../../modules/asset";

function PublishSucess({ videoLink, videoName, publishedId }) {
  const copy_link = `${process.env.REACT_APP_URL}view/${publishedId}`;
  const navigate = useNavigate();
  const [copyAlert, setCopyAlert] = useState(false);

  const handleCopy = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(copy_link);
      setCopyAlert(true);
    } else {
      unsecuredCopyToClipboard(copy_link);
      setCopyAlert(true);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="published-video">
        <ReactPlayer
          controls
          width={865}
          height={486}
          className="d-inline-block"
          url={videoLink}
          progressInterval={100}
        ></ReactPlayer>
        <div className="published-video-name">
          <div>{videoName}</div>
        </div>
        <div className="d-flex align-items-center ms-4">
          <div className="published-content">
            <div className="published-item w-100">
              <div className="d-flex align-items-center mb-3">
                <FiShare2 className="me-2" />
                Share this video
              </div>
              <div className="copy-link">
                <span className="copy-link-url">{copy_link}</span>
                <div className="text-center">
                  <FiCopy onClick={handleCopy} />
                  <div className="copy-link-alert">
                    {copyAlert ? "Copied" : "Copy"}
                  </div>
                </div>

              </div>
            </div>
            <button
              className="btn fullwidth-btn mb-4"
              onClick={() => navigate("/all-video")}
            >
              Watch More Videos
            </button>
            <button
              className="btn color-bordered-btn back-profile-btn w-100 mb-4"
              onClick={() => navigate("/dashboard")}
            >
              Back to Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishSucess;
