import { useState } from "react";
import arrowRight from "../../images/arrow-right.png";
import arrowBottom from "../../images/arrow-bottom.png";
import siteIcon from "../../images/site-icon.png";
import usersIcon from "../../images/users-icon.png";
import cameraIcon from "../../images/camera-icon.png";
import loadingIcon from "../../images/loading-icon.png";
import "./index.scss";
import SiteTab from "./siteTab";
import VideoTab from "./videoTab";
import UserTab from "./userTab";
import NewServiceTab from "./newServiceTab";

function GeneralManagement() {
  const [tab, setTab] = useState({
    site: false,
    users: false,
    videos: false,
    newService: false,
  });

  // functions
  const handleTab = (list) => {
    let tempTab = { ...tab };
    tempTab[list] = !tempTab[list];
    setTab(tempTab);
  };

  return (
    <div>
      <div className="management-header">General Management</div>
      <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={siteIcon} alt="" />{" "}
              <span className="card-content-header">Site</span>
            </div>
            <div className="card-desc">
              Visitors Videos Viewed, Clicked Prompters, Finished Videos,
              Signups, Published Videos, Visitors Time On Site, Users Time On
              Site
            </div>
          </div>
          <div className="expand-card" onClick={() => handleTab("site")}>
            <img src={tab.site ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.site && <SiteTab />}
      </div>
      <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={usersIcon} alt="" />{" "}
              <span className="card-content-header">Users</span>
            </div>
            <div className="card-desc">
              Review users by ID Number, Prompters, Videos Block or Authorize
              Users
            </div>
          </div>
          <div className="expand-card" onClick={() => handleTab("users")}>
            <img src={tab.users ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.users && <UserTab />}
      </div>
      <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={cameraIcon} alt="" />{" "}
              <span className="card-content-header">Videos</span>
            </div>
            <div className="card-desc">
              Review videos by number, creation date, prompters, views Delete or
              give videos
            </div>
          </div>
          <div className="expand-card" onClick={() => handleTab("videos")}>
            <img src={tab.videos ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.videos && <VideoTab />}
      </div>
      <div className="dashboard-option-card">
        <div className="d-flex align-items-center justify-content-between">
          <div className="card-content">
            <div className="d-flex align-items-center">
              <img src={loadingIcon} alt="" />{" "}
              <span className="card-content-header">Services</span>
            </div>
            <div className="card-desc">
              Review New services by platform, action, request date or link
              Approve or Deny services
            </div>
          </div>
          <div className="expand-card" onClick={() => handleTab("newService")}>
            <img src={tab.newService ? arrowBottom : arrowRight} alt="" />
          </div>
        </div>
        {tab.newService && <NewServiceTab /> }
      </div>
    </div>
  );
}

export default GeneralManagement;
