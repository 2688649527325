import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { FiMinusCircle } from "react-icons/fi";
import {
  delete_service,
  get_requested_services,
  update_icon,
} from "../../api/video";

function NewServiceTab() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    get_requested_services().then((res) => {
      if (res.status === 200) {
        setServiceList(res.data.services);
        setLoading(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  }, [loading, navigate]);

  const handleRemove = (id) => {
    setLoading(true);
    delete_service(id).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setLoading(false);
      } else {
        if (res.data.message === "Token is invalid or has expired!") {
          localStorage.removeItem("tokens");
          navigate("/login");
          window.location.reload();
        }
      }
    });
  };

  const handleImage = (e, id) => {
    let req_body = new FormData();
    let file = e.target.files[0];
    if (file) {
      req_body.append("image", file);
      let req_data = {
        service_id: id,
      };
      req_body.append("data", JSON.stringify(req_data));
      update_icon(req_body).then((res) => {
        if (res.status === 200) {
          let temp_service_list = JSON.parse(JSON.stringify(serviceList));
          let updated_service_list = temp_service_list.map((item) => {
            if (item.id === id) {
              return { ...item, icon_url: URL.createObjectURL(file) };
            }
            return item;
          });
          setServiceList(updated_service_list);
        } else {
          if (res.data.message === "Token is invalid or has expired!") {
            localStorage.removeItem("tokens");
            navigate("/login");
            window.location.reload();
          }
        }
      });
    }
  };

  return (
    <>
      <table className="tab-table">
        <thead>
          <tr>
            <th>Platform</th>
            <th>Request Date</th>
            <th>Link</th>
            <th>Review Service</th>
          </tr>
        </thead>
        <tbody>
          {serviceList.map((service, index) => (
            <tr key={index}>
              <td>{service.service_name}</td>
              <td>{service.request_date}</td>
              <td className="service-link">{service.service_link}</td>
              <td>
                <span>
                  <input
                    type="file"
                    accept="image/*"
                    id="add-logo"
                    onChange={(e) => handleImage(e, service.id)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="add-logo">Add Logo</label>
                </span>
                <img
                  className="icon-img"
                  src={
                    service.icon_url
                      ? service.icon_url
                      : "https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/logo.jpg"
                  }
                  alt=""
                />
              </td>
              <td>
                <span
                  className="deny-text"
                  onClick={() => handleRemove(service.id)}
                >
                  <FiMinusCircle /> Deny
                </span>
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    </>
  );
}

export default NewServiceTab;
