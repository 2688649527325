import React from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import youtubeIcon from "../../images/youtube-icon.png";
import playIcon from "../../images/play_icon.png";
import "./index.scss";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 1000,
  arrows: false,
  adaptiveHeight: true,
};

function LandingHeaderMobile() {
  const navigate = useNavigate();

  const handleScroll = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth", // Optional smooth scrolling animation
    });
  };
  return (
    <div className="landing-header-mobile">
      <div className="header-content-mobile">
        <div className="header-text1-mobile">
          Connect your videos to
          <div className="d-flex">
            <Slider {...settings}>
              <span className="slide-text-mobile">
                <img className="slider-icon-mobile" src={youtubeIcon} alt="" />
                YouTube
              </span>
              <span className="slide-text-mobile">
                <img
                  className="slider-icon-mobile"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Connect/linkedin.png"
                  alt=""
                />
                LinkedIn
              </span>
              <span className="slide-text-mobile">
                <img
                  className="slider-icon-mobile"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/landing_page/wikipedia.png"
                  alt=""
                />
                Wikipedia
              </span>
              <span className="slide-text-mobile">
                <img
                  className="slider-icon-mobile"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Listen/spotify-icon.png"
                  alt=""
                />
                Spotify
              </span>
              <span className="slide-text-mobile">
                <img
                  className="slider-icon-mobile"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/landing_page/HBO.png"
                  alt=""
                />
                HBO
              </span>
              <span className="slide-text-mobile">
                <img
                  className="slider-icon-mobile"
                  src="https://metapromptvideostorage.s3.eu-west-1.amazonaws.com/icons/Learn/imdb-icon.png"
                  alt=""
                />
                IMDB
              </span>
            </Slider>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <ReactPlayer
            controls
            width="100%"
            height={180}
            playing
            url={
              "https://cicd-metaprompt.s3.eu-west-1.amazonaws.com/Intro_metapropter.mp4"
            }
            playIcon={<img src={playIcon} alt="Play" />}
            light={"https://cicd-metaprompt.s3.eu-west-1.amazonaws.com/Intro_metapropter.png"}
          />
        </div>
        <div className="header-text2-mobile">
          <div className="text-white mb-5">
            We fill videos with knowledge and actions thanks to our synchronized
            cards. <br /> <br /> Made for movie lovers, design aficionados,
            advertising pros, sports analytics and fans of Casablanca.
          </div>
          <button
            className="btn fullwidth-btn-mobile text-white mb-3"
            onClick={() => navigate("/signup")}
          >
            Beta Sign up
          </button>
          <button
            className="btn fullwidth-bordered-btn-mobile mb-3"
            onClick={() => navigate("/all-video")}
          >
            Watch Videos
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingHeaderMobile;
